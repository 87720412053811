<template>
  <div class="contacts__container">
    <div class="texts">
      <h2 class="center">{{ $t('contacts.title1') }}</h2>
    </div>

    <!-- <main>
      <div class="inputs__fields">
        <div class="no-progress">
          <p class="contact__title">{{ $t('contacts.paragraph') }}</p>
          <p class="contact__title nopadding">{{ $t('contacts.paragraph2') }}</p>
        </div>
        <div>
          <form class="contacts__notifications" @submit.prevent="submit">
            <p class="contact__title nopadding">{{ $t('contacts.yourinfo') }}</p>

            <div class="personal__info">
              <input v-model="form.name" type="text" placeholder="Вашето име" @input="getValueFromInputs" required />

              <input v-model="form.phone" type="phone" placeholder="Телефон" @input="getValueFromInputs" required />

              <input v-model="form.email" type="email" placeholder="Мейл" @input="getValueFromInputs" required />
            </div>
          </form>
        </div>

        <div class="choice__textarea">
          <p>{{ $t('contacts.moreinfo') }}</p>
          <textarea
            v-model="form.message"
            placeholder="Повече информация"
            @input="getValueFromInputs"
            required
          ></textarea>

          <div class="btn">
            <Button @click="submit">{{ $t('buttons.button3') }}</Button>
          </div>
        </div>

      </div>
    </main> -->
  </div>
</template>

<script>
// import Button from '@/components/Button';
import data from '../../locale/bg.json';

export default {
  components: {
    // Button,
  },
  data() {
    return {
      active: false,
      stepForm: false,
      stepTaste: false,
      stepOptions: false,
      stepType: false,
      stepPeriod: false,
      stepMsg: false,
      form: {
        name: '',
        phone: '',
        email: '',
        company: '',
        coffeeTaste: [],
        optionsBrand: [],
        coffeeType: [],
        period: '',
        message: '',
      },
    };
  },

  methods: {
    getValueFromInputs() {
      if (this.form.name !== '' && this.form.phone !== '' && this.form.email !== '') {
        this.stepForm = true;
      } else {
        this.stepForm = false;
      }
      if (this.form.message !== '') {
        this.stepMsg = true;
      } else {
        this.stepMsg = false;
      }
    },
    pickCoffeeTaste(el) {
      if (this.form.coffeeTaste.indexOf(el) === -1) {
        this.form.coffeeTaste.push(el);
        this.stepTaste = true;
      } else {
        const i = this.form.coffeeTaste.indexOf(el);
        this.form.coffeeTaste.splice(i, 1);

        if (this.form.coffeeTaste.length < 1) {
          this.stepTaste = false;
        }
      }
    },
    pickOptionsBrand(el) {
      if (this.form.optionsBrand.indexOf(el) === -1) {
        this.form.optionsBrand.push(el);
        this.stepOptions = true;
      } else {
        const i = this.form.optionsBrand.indexOf(el);
        this.form.optionsBrand.splice(i, 1);
        if (this.form.optionsBrand.length < 1) {
          this.stepOptions = false;
        }
      }
    },
    pickCoffeeType(el) {
      if (this.form.coffeeType.indexOf(el) === -1) {
        this.form.coffeeType.push(el);
        this.stepType = true;
      } else {
        const i = this.form.coffeeType.indexOf(el);
        this.form.coffeeType.splice(i, 1);
        if (this.form.coffeeType.length < 1) {
          this.stepType = false;
        }
      }
    },
    activateTime() {
      if (this.form.perion !== '') {
        this.stepPeriod = true;
      }
    },
    // isVisible() {
    //   const wrapper = this.$refs.boxContainer.getBoundingClientRect();
    //   if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
    //     document.removeEventListener('scroll', this.isVisible);
    //     setTimeout(() => {
    //       this.active = true;
    //     });
    //   }
    // },
    submit() {
      if (this.isValid()) {
        this.form.from = window.location.href;
        fetch(`${process.env.VUE_APP_BASE_URI}/email/inquiry`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.form),
        })
          .then((response) => response.json())
          .then((data) => {
            this.success = data.success;
           this.form = {
                name: '',
                phone: '',
                email: '',
                message: '',
           }
            if (data.success) {
              this.form = {
                name: '',
                phone: '',
                email: '',
                company: '',
                coffeeTaste: [],
                optionsBrand: [],
                coffeeType: [],
                period: '',
                message: '',
              };
              this.stepForm = false;
              this.stepTaste = false;
              this.stepType = false;
              this.stepOptions = false;
              this.stepPeriod = false;
              this.stepMsg = false;
            }
          })
          .catch((err) => console.log(err));
      }
    },
    isValid() {
      if (!this.form.name) {
        this.$snotify.warning(data.errorMessages.name);
      } else if (!this.validatePhoneNumber(this.form.phone)) {
        this.$snotify.warning(data.errorMessages.phone);
      } else if (!this.validateEmail(this.form.email)) {
        this.$snotify.warning(data.errorMessages.email);
      }
      
      // else if (!this.form.company) {
      //   this.$snotify.warning(data.errorMessages.company);
      // } else if (this.form.coffeeTaste.length < 1) {
      //   this.$snotify.warning(data.errorMessages.coffeeTaste);
      // } else if (this.form.optionsBrand.length < 1) {
      //   this.$snotify.warning(data.errorMessages.optionsBrand);
      // }
      // else if (this.form.period.length < 1) {
      //   this.$snotify.warning(data.errorMessages.period);
      // }
      else if (!this.form.message) {
        this.$snotify.warning(data.errorMessages.message);
      } else {
        this.$snotify.success(data.errorMessages.success);
        return true;
      }
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(String(email).toLowerCase());
    },

    validatePhoneNumber(phone) {
      const re = /^[0-9 ]+$/;
      return re.test(String(phone).toLowerCase());
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.contacts__container {
  .texts {
    margin: 0 auto;
    h2 {
      padding: 16px 0;
      margin-bottom: 0;
    }
  }

  main {
    background: linear-gradient(#d3e0e5, #eef2f4);
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: calc(100% - 2 * $sides);
    margin: 0 auto;
    border-radius: 8px;

    @media screen and (min-width: 1920px) {
      max-width: $maxWidthWithSides;
    }

    //.inputs__fields {
      // > div:not(.no-progress) {
      //   position: relative;
      //   &.gradient {
      //   background: rgb(211, 224, 229);
      //   background: linear-gradient(0deg, rgba(211, 224, 229, 0.54) 0%, rgba(238, 242, 244, 0) 100%);
      //   }
      //   &:not(:nth-child(2)):not(:last-child) {
      //     border-left: 2px solid #8e8e8e;
      //   }
      //   &:nth-child(2),
      //   &:last-child {
      //     &::before {
      //       background-color: #8e8e8e;
      //       content: '';
      //       height: 50%;
      //       left: 0;
      //       position: absolute;
      //       top: 50%;
      //       width: 2px;
      //     }
      //  }
      //   &:last-child {
      //     &::before {
      //       top: 0;
      //     }
      //   }

      //   > .icon {
      //     background: url('../../assets/img/contacts/uncomplete.svg');
      //     background-size: 100%;
      //     cursor: pointer;
      //     display: block;
      //     height: 66px;
      //     width: 66px;
      //     max-height: 66px;
      //     max-width: 66px;
      //     left: -33px;
      //     top: 50%;
      //     position: absolute;
      //     z-index: 1;
      //     @include transform(translateY(-50%));

      //     &:before {
      //       background: url('../../assets/img/contacts/complete.svg');
      //       background-size: 100%;
      //       background-repeat: no-repeat;
      //       content: '';
      //       cursor: pointer;
      //       display: block;
      //       height: 100%;
      //       opacity: 0;
      //       position: absolute;
      //       width: 100%;
      //       @include transition($transition);
      //     }

      //     @media screen and (max-width: $sm) {
      //       & {
      //         height: 5vw;
      //         left: -2.5vw;
      //         max-height: 5vw;
      //         max-width: 5vw;
      //         width: 5vw;
      //       }
      //     }

      //     &.complete {
      //       &:before {
      //         opacity: 1;
      //       }
      //     }
      //   }
      // }
    //}

    .inputs__fields {
      // flex-basis: 700px;

      .contact__title {
        color: #444444;
        font-weight: 600;
        margin: 0 auto;
        padding-top: 20px;
        text-align: center;
        width: 100%;

        &.nopadding {
          padding: 0;
          padding-bottom: 25px;
        }
      }

      @media (max-width: $xsm) {
        .contact__title {
          width: 85%;
        }
      }

      .contacts__notifications {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding:0 30px;
        width: 100%;
        // background: rgb(238, 242, 244);
        // background: linear-gradient(180deg, rgba(238, 242, 244, 0.7035189075630253) 0%, rgba(211, 224, 229, 1) 70%);

        .personal__info {
          align-self: center;
          width: 100%;
          input {
            border: none;
            border-radius: 2px;
            font-size: 11px;
            font-weight: 600;
            padding-left: 10px;
            height: 37px;
            margin-bottom: 10px;
            outline: none;
            opacity: 0.7;
            width: 32.4%;

            &.not-valid {
              background-color: red;
            }
          }

          input::placeholder {
            color: rgba($color: #000, $alpha: 0.5);
            padding-left: 1px;
          }

          input:not(:last-child) {
            margin-right: 10px;
          }
        }

        @media (max-width: $xsm) {
          .personal__info {
            display: flex;
            flex-direction: column;
            max-width: 400px;
            input {
              width: 100%;
            }
            input:not(:last-child) {
              margin-right: 0;
            }
          }
        }

        .company__info {
          align-self: center;
          input {
            align-self: center;
            border: none;
            box-shadow: 0 0 50px #ccc;
            border-radius: 2px;
            font-weight: 600;
            font-size: 11px;
            height: 37px;
            margin: 0;
            margin-right: 0;
            outline: none;
            opacity: 0.7;
            padding-left: 10px;
            width: 680px;
          }
        }

        @media (max-width: $xsm) {
          .company__info {
            width: 80%;
            input {
              width: 100%;
            }
          }
        }

        input::placeholder {
          color: rgba($color: #000, $alpha: 0.5);
        }
      }

      @media (max-width: $xsm) {

      }

      .choice__taste {
        color: #444444;
        font-weight: 600;
        margin: 0 auto;
        text-align: center;
        padding: 20px 0;
        width: 568px;
      }

      @media (max-width: $xsm) {
        .choice__taste {
          width: 100%;
          // margin-left: -18px;
          p {
            width: 100%;
            padding: 0 $sides;
          }
        }
      }

      .selected__fields {
        align-items: center;
        // background: rgb(238, 242, 244);
        // background: linear-gradient(180deg, rgba(238, 242, 244, 0.7035189075630253) 0%, rgba(211, 224, 229, 1) 54%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        padding: 10px 30px;

        div {
          display: flex;
          justify-content: center;

          .option {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-basis: 17%;
            padding: 0 25px;
            text-align: center;
            max-height: 100%;
            max-width: 100%;

            .icon {
              background-image: url('../../assets/img/contacts/normal.svg');
              background-size: 100%;
              cursor: pointer;
              display: block;
              height: 100px;
              width: 100px;
              max-height: 100%;
              max-width: 100%;
              transition: 0.3s;
              -moz-transition: -moz-transform 0.3s, width 0.3s;
              -webkit-transition: -webkit-transform 0.3s, width 0.3s;
              position: relative;

              &:before {
                background: url('../../assets/img/contacts/ready.svg') no-repeat;
                background-size: 100%;
                background-size: cover;
                content: '';
                cursor: pointer;
                display: block;
                height: 100px;
                left: 0;
                max-height: 100%;
                max-width: 100%;
                opacity: 0;
                top: 0;
                position: absolute;
                width: 100px;
                z-index: 1;

                @media screen and (min-width: $xsm) {
                  &:hover {
                    background: url('../../assets/img/contacts/ready.svg') no-repeat;
                    background-size: cover;
                    max-height: 100px;
                    max-width: 100px;
                  }
                }
              }

              &:after {
                background: url('../../assets/img/contacts/hover.svg') no-repeat;
                background-size: 100%;
                background-size: cover;
                content: '';
                cursor: pointer;
                display: block;
                font-weight: 600;
                height: 100px;
                left: 0;
                max-height: 100%;
                max-width: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                width: 100px;
                z-index: 2;
                @include transition(all 0.2s $cubic);
              }
              @media screen and (min-width: $xsm) {
                &:hover {
                  &:after {
                    opacity: 1;
                  }
                }
              }
              &.checked:before {
                opacity: 1;
                z-index: 2;
              }
            }

            .seledted__field {
              color: #444444;
              font-weight: 600;
              margin: 0 auto;
              padding-top: 10px;
              width: 80%;
              &.dec {
                width: 90%;
              }
            }
          }
        }

        @media (max-width: $xsm) {
          width: 100%;
          .option {
            &.option__active {
              animation: 2s ease-in 0.5s alternate-reverse both slidein;

              @keyframes slidein {
                30% {
                  margin-left: -30px;
                }
              }
            }
          }
        }
      }

      @media (max-width: $s) {
        .selected__fields {
          align-items: flex-start;
          width: 100%;
          justify-content: flex-start;
          // margin-left: -18px;
          overflow: auto;
        }

        .selected__fields::-webkit-scrollbar {
          div {
            scrollbar-width: none;
          }
        }
      }

      @media (max-width: $s) {
        .selected__fields {
          width: 100%;
        }
      }

      @media (max-width: 480px) {
        .selected__fields {
          width: 100%;
        }
      }

      .choice__period {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // background: rgb(238, 242, 244);
        // background: linear-gradient(180deg, rgba(238, 242, 244, 0.7035189075630253) 0%, rgba(211, 224, 229, 1) 54%);

        .choice__privatebrand {
          color: #444444;
          font-weight: 600;
          margin: 0 auto;

          text-align: center;
          width: 568px;

          p {
            font-weight: 600;
            padding: 30px 0;
            text-align: center;
          }
        }

        @media (max-width: $xsm) {
          .choice__privatebrand {
            width: 95%;
            p {
              width: 90%;
            }
          }
        }

        div {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding-bottom: 20px;
          .time {
            align-self: center;
            background-color: #ffffff;
            box-shadow: 0 0 50px #ccc;
            border-radius: 2px;
            color: #000000;
            cursor: pointer;
            font-weight: 600;
            height: 37px;
            line-height: 36px;
            text-align: center;
            width: 200px;
            @include transition(all 0.2s $cubic);
            @include noselect();

            &.checked {
              background-color: #0d9f34;
              color: #ffffff;
            }

            > span {
              @include transition(font-size 0.2s $cubic);
            }

            @media screen and (min-width: $xsm) {
              &:hover {
                > span {
                  font-size: 16px;
                }
              }
            }
          }

          .time:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      @media (max-width: $xsm) {
        .choice__period {
          align-items: center;
          flex-direction: column;
          // margin-left: -18px;
          width: 100%;
          div {
            .time {
              margin-bottom: 10px;
              width: 100%;
            }

            .time:not(:last-child) {
              margin-right: 0px;
            }
          }
        }
      }

      .choice__textarea {
        display: flex;
        justify-content: center;
        flex-direction: column;
        // background: rgb(238, 242, 244);
        // background: linear-gradient(180deg, rgba(238, 242, 244, 0.7035189075630253) 0%, rgba(211, 224, 229, 1) 54%);
        padding: 0 30px;

        p {
          color: #444444;
          font-weight: 600;
          text-align: center;
          padding: 20px 0 30px 0;
        }

        textarea {
          align-self: center;
          border: none;
          border-radius: 2px;
          box-shadow: 0 0 50px #ccc;
          font-size: 11px;
          font-weight: 600;
          height: 226px;
          outline: none;
          opacity: 0.7;
          padding: 11px 15px;
          resize: none;
          width: 100%;
        }

        textarea::placeholder {
          color: rgba($color: #000, $alpha: 0.5);
        }

        .btn {
          display: flex;
          justify-content: center;
          padding: 20px 0;
        }
      }

      @media (max-width: $xsm) {
        .choice__textarea {
          textarea {
            height: 182px;
            max-width: 400px;
            width: 100%;
          }
        }
      }
    }
    @media (max-width: $xsm) {
      .inputs__fields {
        width: 100%;
        // div {
        //   margin-left: -20px;
        // }

        &.second {
          div {
            margin-left: 0;
          }
        }
      }
    }
  }

  @media (max-width: $xsm) {
    main {
      border-radius: 10px;
      // margin: 0 20px;

      .progress__indicator {
        margin-left: 0 auto;
      }
    }
  }
}
</style>
