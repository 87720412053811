<template>
  <div class="main-activities">
    <div class="texts">
      <h2 class="center">{{ $t('home.basic.title') }}</h2>
      <p class="center">{{ $t('home.basic.desc') }}</p>
    </div>

    <div class="overview__container" ref="boxContainer">
      <section class="main__content">
        <main class="test" :class="{ test__active: active && i==0 }" v-for="(te, i) in test" :key="i">
          <router-link :to="te.route" class="main_box">
            <div class="bg">
              <div
                class="imgage"
                :style="{
                  backgroundImage: `url(${require(`../../assets/img/home/${i+1}.png`)})`,
                }"
              >
                <div class="gradient"></div>
              </div>
              <div class="text">
                <p>
                  {{ $t(`home.production.p${i+1}`) }}
                </p>
                <span>{{ $t(`home.production.span${i+1}`) }} </span>
              </div>
            </div>
          </router-link>
        </main>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      test:[
        {route:'/ese'},
        {route:'/beans-and-grinded'},
        {route:'/products?c=capsules-and-pods'},
        {route:'/brands'},
        {route:'/products?scr=vending'},
        {route:'/blends'},
        {route:'/distribution'},
        {route:'/balkam-group'}
      ]
    };
  },
  methods: {
    isVisible() {
      const wrapper = this.$refs.boxContainer.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window
      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.active = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.main-activities {
  margin: 0 auto;
  .texts {
    max-width: $maxWidthWithSides;
    margin: 40px auto;
  }
}
.overview__container {
  display: flex;
  flex-direction: column;
  max-width: $maxWidth;
  margin: 0 auto;
  overflow-x: hidden;
  padding-bottom: 2vh;
  @include noselect();

  .main__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .bg {
    display: flex;
    background: #a0642f;
    border-radius: 20px;
    background-position: center;
    height: calc(((100vw - (2 * #{$sides})) - 32px) / 4);
    max-width: 100%;
    margin: 4px;
    position: relative;
    width: 23vw;

    @media screen and (min-width: 1920px) {
      & {
        width: 442px;
        height: 442px;
      }
    }

     .imgage {
      background-size: 100%;
      background-position: center;
      border-radius: 20px;
      opacity: 0.5;
      @include transition($transition);

      .gradient {
        background: transparent linear-gradient(180deg, #00000000 0%, #00000000 65%, #000000 100%) 0% 0% no-repeat
          padding-box;
        border-radius: 20px;
        height: 100%;
        width: 23vw;

        @media screen and (min-width: 1920px) {
          & {
            width: 442px;
            height: 442px;
          }
        }
      }
    }

    &:hover {
     .imgage {
        background-size: 140%;
        opacity: 1;
      }
    }

    .text {
      align-items: center;
      bottom: 0;
      color: white;
      display: flex;
      flex-direction: column;
      left: 0;
      text-align: center;
      position: absolute;
      width: 100%;
      padding: 0 20px 20px;
      p {
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 5px;
      }
      span {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .seven {
      padding-bottom: 60px;
    }
  }
}
@media all and(max-width: $xsm) {
  .overview__container {
    flex-direction: row;
    overflow-x: scroll;
    padding-right: $sides;
    scroll-snap-type: x mandatory;
    @include scrollbar(0, #fff);
    .main__content {
      flex-wrap: initial;
      main {
        scroll-snap-align: start;
        padding-left: $sides;
        &.test__active {
          animation: 2s ease-in 0.5s alternate-reverse both slidein;
          @keyframes slidein {
            30% {
              margin-left: -120px;
            }
          }
        }
      }
    }
  }
  .main_box {
    .bg {
      margin: 0;
      height: 360px;
      width: 85vw;

      .imgage {
        .gradient {
          width: 85vw;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0;
  }
}
@media all and(max-width:430px) {
  .main_box {
    overflow-y: hidden;
    .bg {
      display: flex;
      background: #a0642f;
      border-radius: 20px;
      height: 85vw;
      width: 85vw;
      position: relative;

        .imgage {
        .gradient {
          width: 85vw;
          height: 85vw;
        }
      }
    }
  }
}
</style>
