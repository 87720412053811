var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('TheHeader'),_c('transition',{attrs:{"name":"slide","mode":"out-in"}},[_c('router-view')],1),_c('vue-snotify'),(
      _vm.$route.name !== 'BrandSpetema' &&
      _vm.$route.name !== 'BrandBalkam' &&
      _vm.$route.name !== 'BrandSantos' &&
      _vm.$route.name !== 'BrandNapoli' &&
      _vm.$route.name !== 'BrandLavista' &&
      _vm.$route.name !== 'BrandVerdico' &&
      _vm.$route.name !== 'BrandCaprimo' &&
      _vm.$route.name !== 'BrandVanHouten' &&
      _vm.$route.name !== 'BrandMaison' &&
      _vm.$route.name !== 'BrandTe' &&
      _vm.$route.name !== 'BrandBasilur' &&
      _vm.$route.name !== 'BrandGreenIsland'
    )?_c('TheFooter'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }