<template>
  <header class="hdr">
    <div class="hdr-content">
      <router-link class="hdr__lg" to="/">
        <img src="@/assets/img/logo-h.svg" alt="Balkam" />
      </router-link>
      <nav class="hdr__nv" :class="{ hdr__active: open }">
        <TheHeaderLocaleMobile v-if="open" class="mobile__lng" />
        <router-link class="hdr__nv__lnk mobile" to="/">
          <span>{{ $t('header.nav.link1.home') }}</span>
        </router-link>
        <router-link class="hdr__nv__lnk" to="/balkam-group">
          <span>{{ $t('header.nav.link1.name') }}</span>
          <TheHeaderDropdown v-if="!open">
            <router-link :to="{ path: 'balkam-group', query: { scr: 'about' } }">
              {{ $t('header.nav.link1.link1') }}</router-link
            >
            <router-link :to="{ path: 'balkam-group', query: { scr: 'cycle' } }">{{
              $t('header.nav.link1.link2')
            }}</router-link>
            <router-link :to="{ path: 'balkam-group', query: { scr: 'technology' } }">{{
              $t('header.nav.link1.link3')
            }}</router-link>
            <router-link :to="{ path: 'balkam-group', query: { scr: 'sustainability' } }">{{
              $t('header.nav.link1.link4')
            }}</router-link>
            <router-link :to="{ path: 'balkam-group', query: { scr: 'certificates' } }">{{
              $t('header.nav.link1.link5')
            }}</router-link>
          </TheHeaderDropdown>
        </router-link>
        <router-link
          class="hdr__nv__lnk"
          :class="{
            'router-link-exact-active':
              ['BrandSpetema', 'BrandBalkam', 'BrandSantos', 'BrandNapoli', 'BrandLavista', 'BrandVerdico'].indexOf(
                $route.name
              ) > -1,
          }"
          to="/brands"
        >
          <span>{{ $t('header.nav.link2.name') }}</span>
          <TheHeaderDropdown v-if="!open">
            <router-link to="/brandSpetema">{{ $t('header.nav.link2.link1') }}</router-link>
            <router-link to="/brandBalkam">{{ $t('header.nav.link2.link2') }}</router-link>
            <router-link to="/brandSantos">{{ $t('header.nav.link2.link3') }}</router-link>
            <router-link to="/brandLavista">{{ $t('header.nav.link2.link4') }}</router-link>
            <router-link to="/brandNapoli">{{ $t('header.nav.link2.link5') }}</router-link>
            <router-link to="/BrandVerdico">{{ $t('header.nav.link2.link6') }}</router-link>
            <router-link to="/BrandCaprimo">{{ $t('header.nav.link2.link7') }}</router-link>
            <router-link to="/BrandVanHouten">{{ $t('header.nav.link2.link8') }}</router-link>
            <router-link to="/BrandMaison">{{ $t('header.nav.link2.link9') }}</router-link>
            <router-link to="/BrandTe">{{ $t('header.nav.link2.link10') }}</router-link>
            <router-link to="/BrandBasilur">{{ $t('header.nav.link2.link11') }}</router-link>
            <router-link to="/BrandGreenIsland">{{ $t('header.nav.link2.link12') }}</router-link>
          </TheHeaderDropdown>
        </router-link>
        <router-link
          class="hdr__nv__lnk"
          :class="{
            'router-link-exact-active': ['BeansAndGrinded', 'SolubleDrinks'].indexOf($route.name) > -1,
          }"
          to="/products"
        >
          <span>{{ $t('header.nav.link3.name') }}</span>
          <TheHeaderDropdown v-if="!open">
            <router-link to="/products?c=capsules-and-pods">{{ $t('header.nav.link3.link1') }}</router-link>
            <router-link to="/beans-and-grinded">{{ $t('header.nav.link3.link2') }}</router-link>
            <router-link to="/soluble-drinks">{{ $t('header.nav.link3.link3') }}</router-link>
            <router-link :to="{ path: 'products', query: { scr: 'vending' } }">
              {{ $t('header.nav.link3.link4') }}</router-link>
          </TheHeaderDropdown>
        </router-link>
        <router-link class="hdr__nv__lnk" to="/blends">
          <span>{{ $t('header.nav.link4') }}</span>
        </router-link>
        <router-link class="hdr__nv__lnk" to="/distribution">
          <span>{{ $t('header.nav.link5') }}</span>
        </router-link>
        <router-link class="hdr__nv__lnk" to="/contacts">
          <span>{{ $t('header.nav.link6') }}</span>
        </router-link>
        <TheHeaderLocale v-if="!open" />
      </nav>
      <div class="menu" @click="open = !open">
        <img src="@/assets/img/menu-close.svg" v-if="open" alt="" />
        <img src="@/assets/img/menu-mobile.svg" v-else alt="" />
      </div>
    </div>
  </header>
</template>

<script>
import TheHeaderLocale from './TheHeaderLocale.vue';
import TheHeaderLocaleMobile from './TheHeaderLocaleMobile.vue';
import TheHeaderDropdown from './TheHeaderDropdown.vue';
export default {
  components: {
    TheHeaderLocale,
    TheHeaderLocaleMobile,
    TheHeaderDropdown,
  },
  data() {
    return {
      open: false,
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.matchMedia('(max-width: $xsm)').matches;

    window.addEventListener('resize', () => {
      this.isMobile = window.matchMedia('(max-width: $xsm)').matches;
    });
  },
  watch: {
    isMobile() {
      if (!this.isMobile) {
        this.open = false;
      }
    },
    open() {
      if (this.open) {
        document.documentElement.style.overflow = 'hidden';
        return;
      }
      document.documentElement.style.overflow = 'auto';
    },
    $route() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
html body {
  padding-top: $header;
}
.hdr {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  box-shadow: inset 0px 56px 39px #00000029;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  height: $header;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}
.hdr-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $maxWidth;
  position: relative;
  padding: 0;
  top: 20%;
}
.menu {
  display: none;
  cursor: pointer;
}

.hdr__lg {
  cursor: pointer;
  display: inline-flex;
  @include tr();
  @include noselect();
  img {
    height: 30px;
  }
  &:hover {
    @include transform(scale(1.05));
  }
}

.hdr__nv {
  align-items: center;
  display: flex;
}

.hdr__nv__lnk {
  cursor: pointer;
  position: relative;

  @include tr();
  @include noselect();

  span {
    display: inline-block;
    padding: 0 3px;
    position: relative;
    z-index: 1;
  }

  &:not(:first-child) {
    margin-left: 24px;
  }

  &::before {
    background-color: #fff;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    transition: inherit;
    width: 0;
  }
  &:hover {
    color: #000;
    &::before {
      width: 100%;
    }
    .hdr__nv__dd {
      opacity: 1;
      @include transform(initial);
    }
  }

  &.mobile {
    display: none;
  }
}
@media all and(max-width: 1900px) {
  .hdr-content {
    padding: 0 40px;
  }
}

@media all and (min-width: 1141px) {
  .hdr__nv__lnk {
    &.router-link-active,
    &.router-link-exact-active {
      background-color: #fff;
      color: #000;
    }
  }
}

@media all and (max-width: $xsm) {
  .hdr-content {
    top: 50%;
  }
  .hdr__lg {
    display: flex;
    left: 3vh;
    position: absolute;
    z-index: 1;
  }

  .menu {
    display: block;
    position: absolute;
    right: 20px;
  }

  .hdr__nv {
    align-items: center;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    background-color: rgba($color: #000000, $alpha: 0.9);
    box-shadow: inset 0px 56px 39px #00000029;
    display: flex;
    flex-direction: column;
    padding-top: $header;
    position: fixed;
    height: 100vh;
    right: 0px;
    top: 0;
    width: 100%;
    @include transform(translateX(100%));
    @include tr();
    &.hdr__active {
      @include transform(translateX(0%));
    }
  }
  .hdr__nv__lnk {
    align-items: center;
    border-radius: 10px;
    backdrop-filter: blur(30px);
    background-color: rgba($color: #868080, $alpha: 0.3);
    -webkit-backdrop-filter: blur(30px);
    display: flex;
    top: 5%;
    height: 7vh;
    padding: 15px;
    justify-content: center;
    margin-top: 10px;
    width: 90%;
    text-align: center;

    &:not(:first-child) {
      margin-left: 0px;
    }

    &::before {
      display: none;
    }
    &:hover {
      color: rgb(255, 255, 255);
      &::before {
        width: 100%;
      }
    }

    &.mobile {
      display: flex;
    }
  }
}
</style>