<template>
  <div>
    <HomeVideo
      title="СОБСТВЕНА МАРКА"
      title2="КАФЕ НА ЗЪРНА И МЛЯНО КАФЕ"
      subtitle="ИЗБЕРЕТЕ НИ ЗА ВАШАТА"
      source="https://www.youtube.com/embed/f04_BLUx1xg?controls=0&autoplay=1&playlist=f04_BLUx1xg&loop=1&mute=1"
    />
    <main class="beans__and__grinded__container">
      <div class="texts">
        <h2>{{ $t('beans-and-grinded.title') }}</h2>
        <p>{{ $t('beans-and-grinded.desc0') }}</p>
        <p>{{ $t('beans-and-grinded.desc01') }}</p>
        <p>{{ $t('beans-and-grinded.desc02') }}</p>
      </div>

      <section class="main__content">
        <div>
          <img src="../../assets/img/beans-and-grinded/coffee-beans-big.png" />
          <div class="texts">
            <h2>{{ $t('beans-and-grinded.first-colomn.title') }}</h2>
            <p>{{ $t('beans-and-grinded.first-colomn.desc') }}</p>
            <p>{{ $t('beans-and-grinded.first-colomn.desc2') }}</p>
          </div>
        </div>
        <div>
          <img src="../../assets/img/beans-and-grinded/bags.png" />
          <div class="texts">
            <h2>{{ $t('beans-and-grinded.second-colomn.title') }}</h2>
            <p>{{ $t('beans-and-grinded.second-colomn.desc') }}</p>
            <p>{{ $t('beans-and-grinded.second-colomn.desc2') }}</p>
          </div>
        </div>
      </section>

      <div class="texts">
        <h2 class="center">{{ $t('beans-and-grinded.title2') }}</h2>
      </div>
      <div class="choose">
        <router-link to="/coffee-beans">
          <div>
            <img src="../../assets/img/beans-and-grinded/coffee-beans.png" />
            <p>{{ $t('beans-and-grinded.desc') }}</p>
          </div>
          <div class="texts">
            <p>
              Пакетите, които ние използваме притежават специална дюза, която извежда въглеродния диоксид на прясно
              изпеченото кафе и едновременно с това не допуска навлизането на кислород в опаковката.
            </p>
          </div>
        </router-link>
        <router-link to="/grinded">
          <div>
            <img src="../../assets/img/beans-and-grinded/coffee-grinded.png" />
            <p>{{ $t('beans-and-grinded.desc2') }}</p>
          </div>
          <div class="texts">
            <p>
              Опаковъчни линии за кафена зърна и смляно кафе - Разполагаме с десет линии за опаковане на зърна и мляно кафе. Процесът е напълно автоматизиран, а крайният продукт е с много високо качество и дълга трайност.
            </p>
          </div>
        </router-link>
      </div>
    </main>
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
export default {
  components: {
    HomeVideo,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.beans__and__grinded__container {
  color: #444444;
  // max-width: 1140px;
  margin: 0 auto;
  max-width: $maxWidthWithSides;
  overflow-x: hidden;
  text-align: center;

  > .texts h2 {
    margin: 34px 0;
  }
  .main__content {
    display: flex;
    gap: 20px;
    text-align: left;
    margin-top: 40px;
    padding: 0 $sides;
    div {
      // margin: 5vh 1.2vh;
      width: fit-content;

      > img {
        border-radius: 20px;
        height: 21vw;
        max-height: 400px;
        object-fit: cover;
        width: 100%;
      }

      .texts {
        margin-top: 20px;
        padding: 0;
      }
    }
  }
  .choose {
    background: linear-gradient(180deg, rgba(229, 237, 240, 1) 0%, rgba(238, 242, 244, 1) 100%);
    border-radius: 20px;
    display: flex;
    // flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px;
    max-width: $maxWidth;
    .texts {
      padding: 0;
    }
    div:not(.texts) {
      align-items: flex-end;
      border-radius: 20px;
      display: flex;
      height: 21vw;
      justify-content: center;
      max-height: 400px;
      margin-bottom: 1rem;
      overflow: hidden;
      position: relative;
      width: 100%;
      @include hover();
      &::before {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        width: 100%;
        height: 50%;
        z-index: 1;
      }
      img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
      p {
        color: #fff;
        font-size: 26px;
        font-weight: 500;
        text-transform: lowercase;
        padding: 12px;
        position: relative;
        z-index: 2;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
    a {
      display: block;
      width: 50%;
    }
  }
  ::-webkit-scrollbar {
    width: 0;
  }
}
@media all and(max-width: $xsm) {
  .beans__and__grinded__container {
    .main__content {
      flex-direction: column;
      div {
        img {
          width: 100%;
          height: 50vw;
        }
      }
    }
    .choose {
      width: calc(100% - (2 * $sides));
      flex-direction: column;
      a {
        width: 100%;
      }
      div:not(.texts) {
        height: 50vw;
        p {
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>