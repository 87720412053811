var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"container__brand"},[_c('div',{staticClass:"brand__header"},[_c('router-link',{staticClass:"go__back",attrs:{"to":"/brandVerdico"}},[_c('img',{attrs:{"src":require("@/assets/img/brands/back.svg"),"alt":"icon"}})]),_c('img',{staticClass:"brand__logo",attrs:{"src":require("@/assets/img/brands/caprimo/caprimo.svg"),"alt":""}}),_c('img',{staticClass:"brand__bcg",attrs:{"src":require("@/assets/img/brands/caprimo/caprimo.svg"),"alt":""}}),_c('router-link',{staticClass:"go__back__right",attrs:{"to":"/brandVanHouten"}},[_c('img',{attrs:{"src":require("@/assets/img/brands/back.svg"),"alt":"icon"}})])],1),_c('div',{staticClass:"container__clm"},[_c('Carousell',{attrs:{"images":[
        require('../../assets/img/brands/caprimo/p1.png'),
        require('../../assets/img/brands/caprimo/p2.png'),
        require('../../assets/img/brands/caprimo/p3.png'),
        require('../../assets/img/brands/caprimo/p4.png'),
        require('../../assets/img/brands/caprimo/p5.png'),
        require('../../assets/img/brands/caprimo/p6.png'),
        require('../../assets/img/brands/caprimo/p7.png'),
        require('../../assets/img/brands/caprimo/p8.png') ]}}),_c('div',{staticClass:"brand__text"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(_vm.$t('brands.caprimo.title')))]),_c('br'),_c('p',{staticClass:"continets__text"},[_vm._v(_vm._s(_vm.$t('brands.caprimo.desc2')))])]),_vm._m(0)])],1),_c('Button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.redirect('https://coffeemall.bg/')}}},[_vm._v(_vm._s(_vm.$t('buttons.button4')))])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand__continents"},[_c('img',{attrs:{"src":require("@/assets/img/brands/world.svg"),"alt":""}})])}]

export { render, staticRenderFns }