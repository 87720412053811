<template>
  <div class="ftr__address">
    <GmapMap
      ref="gmap"
      :center="center.dsk"
      :zoom="12"
      :options="{
        disableDefaultUI: true,
        styles: mapStyle,
      }"
      map-type-id="terrain"
      class="map"
    >
      <gmap-custom-marker :clickable="true" :draggable="true" :marker="marker.marker1" class="marker--bo">
        <a href="https://goo.gl/maps/fv9D5KNnUPzcLSnXA" target="_blank"
          ><img src="../assets/img/footer/address-bo.svg"
        /></a>
      </gmap-custom-marker>

      <gmap-custom-marker :clickable="true" :draggable="true" :marker="marker.marker2" class="marker--sf">
        <a href="https://goo.gl/maps/69zE1b5CTYfkHawz8" target="_blank">
          <img src="../assets/img/footer/address-sf.svg" />
        </a>
      </gmap-custom-marker>
    </GmapMap>

    <GmapMap
      ref="gmap"
      :center="center.mobile"
      :zoom="11.2"
      :options="{
        disableDefaultUI: true,
        styles: mapStyle,
      }"
      map-type-id="terrain"
      class="map__mobile"
    >
      <gmap-custom-marker :clickable="true" :draggable="true" :marker="marker.marker1" class="marker--bo">
        <a href="https://goo.gl/maps/fv9D5KNnUPzcLSnXA" target="_blank"
          ><img src="../assets/img/footer/address-bo.svg"
        /></a>
      </gmap-custom-marker>

      <gmap-custom-marker :clickable="true" :draggable="true" :marker="marker.marker2" class="marker--sf">
        <a href="https://goo.gl/maps/69zE1b5CTYfkHawz8" target="_blank">
          <img src="../assets/img/footer/address-sf.svg" />
        </a>
      </gmap-custom-marker>
    </GmapMap>

    <div class="ftr__address--bgr"></div>

    <div class="ftr__main--address">
      <section>
        <a href="https://goo.gl/maps/fv9D5KNnUPzcLSnXA" target="_blank">
          <h2 class="title">{{ $t('footer.address.find') }}</h2>
        </a>
      </section>

      <section>
        <a href="https://goo.gl/maps/69zE1b5CTYfkHawz8" target="_blank">
          <p>{{ $t('footer.address.officeHQ') }}</p>

          <strong>{{ $t('footer.address.streetHQ') }}</strong>
        </a>
      </section>

      <!-- <section>
        <a href="https://goo.gl/maps/fv9D5KNnUPzcLSnXA" target="_blank">
          <p>{{ $t('footer.address.officeFactory') }}</p>
          <strong>{{ $t('footer.address.streetFactory') }}</strong>
        </a>
      </section> -->

      <section>
        <p>{{ $t('footer.address.marketing-and-sales') }}</p>
        <p>
          <a href="tel:+3599251516">
            <strong>+359/9251516</strong>
          </a>
        </p>
        <p>
          <a href="tel:070089049">
            <strong>0700 89 049 </strong>
          </a>
        </p>
      </section>

      <section>
        <p>{{ $t('footer.address.own-brand') }}</p>
        <a href="mailto:office@spetema.com">
          <strong>office@spetema.com</strong>
        </a>
      </section>
    </div>
  </div>
</template>

<script>
import googleMapStyles from '@/assets/google-map-styles/google-map-styles.json';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
export default {
  name: 'TheFooterAddress',
  components: {
    'gmap-custom-marker': GmapCustomMarker,
  },
  data() {
    return {
      mapStyle: googleMapStyles,
      center: {
        dsk: {
          lat: 42.74806018483114,
          lng: 23.118991055642557,
        },
        mobile: {
          lat: 42.75470080507729,
          lng: 23.227539756932858,
        },
      },
      marker: {
        marker1: {
          lat: 42.77131508998331,
          lng: 23.192357798669526,
        },
        marker2: {
          lat: 42.71870948178126,
          lng: 23.276607388364017,
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.ftr__address {
  height: 400px;
  position: relative;

  @media all and (min-width: 1141px) {
    .map {
      height: 100%;
      z-index: 1;

      .marker--bo {
        margin-left: -120px;
        margin-top: 70px;
      }

      .marker--sf {
        margin-left: -120px;
      }
    }
  }

  @media all and (min-width: 1141px) {
    .map__mobile {
      height: 400px;
      display: none;
    }
  }

  .ftr__address--bgr {
    height: 100%;
    position: absolute;
    background: rgb(51, 51, 51);
    background: linear-gradient(90deg, rgba(51, 51, 51, 1) 65%, rgba(51, 51, 51, 0.196516106442577) 100%);
    top: 0;
    left: 0;
    z-index: 1;
    width: 40%;
  }

  .ftr__main--address {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    left: $sides;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    section {
      padding: 10px 10px 10px 0;
      a {
        position: relative;
        &:before {
          background-color: #fff;
          bottom: 0;
          content: '';
          height: 1px;
          width: 0;
          position: absolute;
          @include tr();
        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1920px) {
    // .ftr__address--bgr,
    .ftr__main--address {
      left: calc((100vw - $maxWidth) / 2);
    }
  }
}

@media all and (max-width: $xsm) {
  .ftr__address {
    display: flex;
    flex-direction: column-reverse;
    height: initial;
    position: initial;

    .map {
      display: none;
    }

    .map__mobile {
      height: 400px;
      min-height: 400px;
    }

    .ftr__address--bgr {
      display: none;
    }

    .ftr__main--address {
      background-color: #444444;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      left: 0;
      text-align: center;
      position: relative;
      top: 0;
      transform: translate(0, 0);

      section {
        padding: 10px $sides;
      }
    }
  }
}
</style>