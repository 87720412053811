<template>
  <div>
    <main class="blends__container">
      <div class="blends__clm">
        <img src="../../assets/img/blends/b1.png" alt="coffee" />
        <div class="texts">
          <h2>{{ $t('blends.first-colomn.title') }}</h2>
          <p class="subtitle">{{ $t('blends.first-colomn.desc00') }}</p>
          <p>{{ $t('blends.first-colomn.desc') }}</p>
          <p>{{ $t('blends.first-colomn.desc0') }}</p>
        </div>

        <div class="texts">
          <h2>{{ $t('blends.first-colomn.title1') }}</h2>
          <p class="subtitle">{{ $t('blends.first-colomn.desc1') }}</p>
          <p>{{ $t('blends.first-colomn.desc2') }}</p>
          <p>{{ $t('blends.first-colomn.desc3') }}</p>
          <p>{{ $t('blends.first-colomn.desc4') }}</p>
        </div>
      </div>

      <div class="blends__clm">
        <img src="../../assets/img/blends/b2.png" alt="coffee" />

        <div class="texts">
          <h2>{{ $t('blends.second-colomn.title') }}</h2>
          <p class="subtitle">{{ $t('blends.second-colomn.desc1') }}</p>
          <p>{{ $t('blends.second-colomn.desc2') }}</p>
          <p>{{ $t('blends.second-colomn.desc3') }}</p>
        </div>

        <div class="texts">
          <h2>{{ $t('blends.second-colomn.title2') }}</h2>
          <p class="subtitle">{{ $t('blends.second-colomn.text0') }}</p>
          <p>{{ $t('blends.second-colomn.text') }}</p>
          <p>{{ $t('blends.second-colomn.text2') }}</p>
        </div>
      </div>
    </main>

    <div class="box">
      <div class="ftr__numbers__production">
        <div class="ftr__numbers__sct">
          <div class="ftr__numbers__production__item">
            <p>
              <AnimatedNumber :n="19" />
            </p>
            <p><b>ИЗБРАНИ</b> СПЕЦИАЛНИ</p>
            <p>СОРТА <b>КАФЕ</b></p>
          </div>
          <div class="ftr__numbers__production__item">
            <p>
              <AnimatedNumber :n="16" />
            </p>
            <p><b>СТРАНИ</b> ЗА ПРЕХОД НА</p>
            <p>ЗЕЛЕНОТО <b>КАФЕ</b></p>
          </div>
          <div class="ftr__numbers__production__item">
            <p><AnimatedNumber :n="20" /></p>
            <p><b>СТРАНИ</b> ЗА ПРЕХОД НА</p>
            <p>ЗЕЛЕНОТО <b>КАФЕ</b></p>
          </div>
        </div>

        <div class="ftr__numbers__sct">
          <div class="ftr__numbers__production__item">
            <p>
              <AnimatedNumber :n="2" />
            </p>
            <p><b>ОРГАНИЧНИ</b> СПЕЦИАЛНИ</p>
            <p>СОРТА <b>ПРОДУКТА</b></p>
          </div>
          <div class="ftr__numbers__production__item">
            <p>
              <AnimatedNumber :n="6" />
            </p>
            <p><b>НАГРАДИ</b> ЗА ЛЮБИМ ПРОДУКТ</p>
            <p>НА БЪЛГАРСКИЯ <b>ПОТРЕБИТЕЛ</b></p>
          </div>
          <div class="ftr__numbers__production__item">
            <p>
              <AnimatedNumber :n="1" />
            </p>
            <p><b>БРАНД </b> ЗА СПРАВЕДЛИВА</p>
            <p>ТЪРГОВИЯ <b>FAIR TRADE</b></p>
          </div>
        </div>
      </div>
    </div>

    <h2 class="sorts__title">{{ $t('blends.coffee-sorts.title') }}</h2>

    <div class="blends__coffee__images">
      <div class="blends__coffee">
        <img src="../../assets/img/blends/arabica-txt.svg" alt="Arabica Description" />
        <img
          src="../../assets/img/blends/arabica-bean.png"
          alt="Arabica Bean"
          v-tilt="{ perspective: 500, glare: true }"
        />
      </div>
      <div class="blends__coffee">
        <img src="../../assets/img/blends/robusta-txt.svg" alt="Robusta Description" />
        <img
          src="../../assets/img/blends/robusta-bean.png"
          alt="Robusta Bean"
          v-tilt="{ perspective: 500, glare: true }"
        />
      </div>
    </div>

    <div class="texts">
      <p class="subtitle center">{{ $t('blends.text-coffee-info.text1') }}</p>
      <p class="center">{{ $t('blends.text-coffee-info.text2') }}</p>
      <p class="subtitle center">{{ $t('blends.text-coffee-info.text3') }}</p>
      <p class="center">{{ $t('blends.text-coffee-info.text4') }}</p>
      <p class="subtitle center">{{ $t('blends.text-coffee-info.text5') }}</p>
    </div>
  </div>
</template>
<script>
import AnimatedNumber from '../../components/AnimatedNumber.vue';
export default {
  components: {
    AnimatedNumber,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';

.blends__container {
  display: flex;
  gap: 20px;
  margin: 0 auto;
  max-width: $maxWidthWithSides;
  justify-content: space-around;
  padding: 0 $sides;
  .blends__clm {
    flex-basis: 50%;
    padding: 30px 0;

    img {
      height: 317px;
      width: 100%;
    }

    @media (max-width: $xsm) {
      img {
        width: 100%;
        height: auto;
      }
    }

    > .texts {
      margin-top: 20px;
      padding: 0;
    }

    .blends__desc {
      &.bold {
        font-weight: 600;
        font-size: 17px;
      }
    }

    .blends__title {
      padding-bottom: 20px;
      text-align: start;
      font-size: 36px;
      padding-bottom: 50px;
    }

    @media (max-width: $xsm) {
      .blends__title {
        padding-bottom: 20px;
        text-align: start;
        font-size: 36px;

        &.fixPadding {
          padding-bottom: 30px;
        }
      }
    }
  }

  // .blends__clm:not(:last-child) {
  //   padding-right: 30px;
  //   padding-left: 30px;
  // }

  // .blends__clm:not(:first-child) {
  //   padding-right: 30px;
  // }

  @media (max-width: $xsm) {
    & {
      flex-direction: column;
      padding-bottom: 20px;
      padding-top: 20px;
    }
    .blends__clm {
      flex-basis: 100%;
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

.texts {
  max-width: $maxWidthWithSides;
  margin: 0 auto;
}

// ANIMATADENUMBERS

.box {
  position: relative;
  background-color: #000;
  background-image: url('../../assets/img/blends/background.png');
  // https://cdn.discordapp.com/attachments/605276279620763648/902073794108223548/test1-animation.gif
  // ../assets/img/footer/numbers.png
  background-size: cover;
  background-position: center;
}
.ftr__numbers__production {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3% 0;
  height: auto;
  max-width: 1140px;
  margin: 0 auto;

  &::before {
    background-color: #000;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .ftr__numbers__sct {
    justify-content: center;
    display: flex;
  }
  @media (max-width: $xsm) {
    .ftr__numbers__sct {
      justify-content: space-around;
      display: flex;
    }
  }
}

.ftr__numbers__production__item {
  color: #fff;
  text-align: center;
  width: 25%;
  position: inherit;
  z-index: 1;

  b {
    font-size: 18px;
    font-weight: 600;
  }

  @media (max-width: $xsm) {
    b {
      font-size: 13px;
      font-weight: 600;
    }
  }

  p {
    font-size: 13px;
    font-weight: 300;
    &:first-child {
      font-size: 72px;
      font-weight: 200;
    }
  }
  @media (max-width: $xsm) {
    p {
      font-size: 13px;
      font-weight: 300;
      &:first-child {
        font-size: 40px;
        font-weight: 300;
      }
    }
  }
}
.sorts__title {
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
  width: 40%;
}

@media (max-width: $xsm) {
  .sorts__title {
    width: 95%;
    padding-bottom: 0;
  }
}
.blends__coffee__images {
  display: flex;
  margin: 0 auto;
  gap: 30px;
  max-width: $maxWidthWithSides;
  margin-bottom: 40px;
  padding: 0 $sides;

  .blends__coffee {
    flex-basis: 50%;
    // height: 480px;
    // max-width: 885px;
    overflow: hidden;
    position: relative;

    // &::before {
    //   // background: linear-gradient(#541711, #400903);
    //   border-radius: 20px;
    //   content: '';
    //   display: block;
    //   height: 100%;
    //   opacity: 0.1;
    //   width: 100%;
    // }

    img {
      display: inline-block;
      height: auto;
      width: 100%;
      &:last-child {
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
      }
    }

    // @media (max-width: $m) {
    //   img {
    //     height: auto;
    //     width: 95%;
    //   }
    // }

    // @media (max-width: $sm) {
    //   img {
    //     height: auto;
    //     width: 95%;
    //   }
    // }

    // @media (max-width: $xsm) {
    //   img {
    //     height: auto;
    //     width: 75%;
    //   }
    // }

    // @media (max-width: $s) {
    //   img {
    //     height: auto;
    //     width: 97%;
    //   }
    // }
  }

  @media (max-width: $xsm) {
    .blends__coffee {
      flex-basis: 100%;
      width: 100%;
      margin: 0 auto;
    }
  }
}

@media (max-width: $xsm) {
  .blends__coffee__images {
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 30px;
  }
}

.blends__coffee__text {
  max-width: 1800px;
  padding: 0 2.5em;
  margin: 0 auto;
  width: 100%;

  .blends__desc {
    padding-bottom: 20px;
    &.bold {
      font-weight: 600;
      font-size: 17px;
      padding-bottom: 20px;
    }
  }
}

@media (max-width: $xsm) {
  .blends__coffee__text {
    padding: 0 2.5em;
  }
  .texts {
    text-align: center;
  }
}
</style>