<template>
  <section class="container-section">
    <main class="container">
      <div class="elements-box">
        <div class="bg">
          <router-link to="/ese">
            <div class="img">
              <img src="@/assets/img/capsules-and-pods/pod.svg" alt="" />
            </div>
            <p>
              <span>{{ $t('capsules-and-pods.ESE-1') }}</span>
              <span>{{ $t('capsules-and-pods.ESE-2') }}</span>
            </p>
          </router-link>
        </div>
        <div class="element-subtext">
          <p>{{ $t('capsules-and-pods.desc-ese') }}</p>
          <!-- <p>{{ $t('capsules-and-pods.desc2-ese') }}</p> -->
        </div>
      </div>

      <div class="elements-box">
        <div class="bg">
          <router-link to="/dolce-gusto">
            <div class="img">
              <img src="@/assets/img/capsules-and-pods/dg.svg" alt="" />
            </div>
            <p>
              <span>{{ $t('capsules-and-pods.DOLCE-GUSTO-1') }}</span>
              <span>{{ $t('capsules-and-pods.DOLCE-GUSTO-2') }}</span>
            </p>
          </router-link>
        </div>
        <div class="element-subtext">
          <p>{{ $t('capsules-and-pods.desc-dolce-gusto') }}</p>
          <!-- <p>{{ $t('capsules-and-pods.desc2-dolce-gusto') }}</p> -->
        </div>
      </div>

      <div class="elements-box">
        <div class="bg">
          <router-link to="/nespresso">
            <div class="img">
              <img src="@/assets/img/capsules-and-pods/nes.svg" alt="" />
            </div>
            <p>
              <span>{{ $t('capsules-and-pods.NESPRESSO-1') }}</span>
              <span>{{ $t('capsules-and-pods.NESPRESSO-2') }}</span>
            </p>
          </router-link>
        </div>
        <div class="element-subtext">
          <p>{{ $t('capsules-and-pods.desc-nespresso') }}</p>
        </div>
      </div>

      <div class="elements-box">
        <div class="bg">
          <router-link to="/lavazza">
            <div class="img">
              <img src="@/assets/img/capsules-and-pods/lb.svg" alt="" />
            </div>
            <p>
              <span>{{ $t('capsules-and-pods.LAVAZZA-1') }}</span>
              <span>{{ $t('capsules-and-pods.LAVAZZA-2') }}</span>
            </p>
          </router-link>
        </div>
        <div class="element-subtext">
          <p>{{ $t('capsules-and-pods.desc-lavazza') }}</p>
          <!-- <p>{{ $t('capsules-and-pods.desc2-lavazza') }}</p> -->
        </div>
      </div>
    </main>
  </section>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.container-section {
  padding: 0 $sides;
}
.container {
  background: transparent linear-gradient(180deg, #e5edf0 0%, #eef2f4 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  display: flex;
  max-width: $defaultWidth;
  margin: 0 auto;
  padding: 20px 40px;
  gap: 20px;

  .elements-box {
    width: 100%;
    color: #444444;

    .bg {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 20px;
    }

    a {
      align-items: center;
      border: 6px solid #ffffff;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 22vw;
      width: 100%;
      margin-bottom: 20px;

      @include transition(0.3s);

      .img {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
      }
      img {
        width: 60%;
        @include transition(0.2s);
      }

      p {
        align-self: flex-end;
        font-size: 26px;
        line-height: 26px;
        padding: 0 5px 34px;
        text-align: center;
        width: 100%;

        span {
          display: block;
        }
      }
      &:hover {
        background: #d0dde2 10% 10% no-repeat;
        border: 6px solid #ffffff;
        border-radius: 20px;
        background-position: center;
        background-size: 75%;
        color: black;
        @include transition(0.2s);

        img {
          @include transform(scale(1.15));
        }
      }
    }
    &:first-child {
      a {
        img {
          width: 70%;
        }
      }
    }
    &:nth-child(3) {
      a {
        img {
          width: 50%;
        }
      }
    }
  }

  .element-subtext {
    text-align: center;
  }
}
@media all and(max-width: $xsm) {
  .container-section {
    padding: 0;

    .container {
      border-radius: 0px;
      flex-wrap: wrap;
      gap: 10px;
      .bg {
        width: 100%;
      }
      .elements-box {
        margin: 0 auto;
        width: 48%;

        a {
          img {
            width: 25%;
          }
        }

        p {
          padding-bottom: 20px;
        }
      }
    }
  }
}
@media all and(max-width:770px) {
  .container-section {
    .container {
      border-radius: 0px;
      gap: 10px;
      padding: 20px 10px;
      .bg {
        width: 100%;
      }
      .elements-box {
        margin: 0 auto;
        width: 48%;

        a {
          height: 200px;
          margin: 0;
          img {
            width: 40%;
            // height: 35%;
            // width: initial;
          }
          p {
            font-size: 14px;
            line-height: 14px;
            padding-bottom: 10px;
          }
        }
        &:first-child {
          a {
            img {
              width: 45%;
            }
          }
        }
        &:nth-child(3) {
          a {
            img {
              width: 30%;
            }
          }
        }
        .element-subtext {
          padding: 10px 0;
          text-align: center;
        }
      }
    }
  }
}
@media all and(max-width:500px) {
  .container-section {
    .container {
      .bg {
        width: 100%;
      }
      .elements-box {
        margin: 0 auto;
        width: 48%;

        .element-img-and-text-ns {
          background-size: 35vw;
          height: 250px;

          h1 {
            padding: 7px 0px;
            font-size: 15px;
          }
          &:hover {
            background-size: 35vw;
          }
        }
        .element-img-and-text,
        .element-img-and-text-pod {
          background-size: 40vw;
          height: 250px;

          h1 {
            padding: 7px 0px;
            font-size: 15px;
          }
          &:hover {
            background-size: 40vw;
          }
        }
        .element-img-and-text-pod {
          background-size: 50vw;
          height: 250px;

          &:hover {
            background-size: 50vw;
          }
        }
      }
    }
  }
}
</style>
