<template>
  <div>
    <HomeVideo title="РАЗТВОРИМИ НАПИТКИ" title2="" subtitle="ИЗБЕРЕТЕ НИ ЗА ВАШАТА СОБСТВЕНА МАРКА КАФЕ"
    source="https://www.youtube.com/embed/MK32ksvu9DQ?controls=0&autoplay=1&playlist=MK32ksvu9DQ&loop=1&mute=1"
    />

    <main class="soluble__drinks__container">
      <div class="parent">
      <div class="text">
        <h2>{{ $t('soluble-drinks.title') }}</h2>
      </div>

      <section class="logos" :class="{logos__active: active }" ref="logosContainer">
        <div>
          <img src="../../assets/img/soluble-drinks/sol1.png" />
          <h1>{{ $t('soluble-drinks.desc') }}</h1>
          <p>{{ $t('soluble-drinks.mini-desc') }}</p>
        </div>

        <div>
          <img src="../../assets/img/soluble-drinks/sol2.png" />
          <h1>{{ $t('soluble-drinks.desc2') }}</h1>
          <p>{{ $t('soluble-drinks.mini-desc2') }}</p>
        </div>

        <div>
          <img src="../../assets/img/soluble-drinks/sol3.png" />
          <h1>{{ $t('soluble-drinks.desc3') }}</h1>
          <p>{{ $t('soluble-drinks.mini-desc3') }}</p>
        </div>
      </section>

      <section>
        <div class="img-and-text-left">
          <img src="../../assets/img/soluble-drinks/cup.png" />

          <div>
            <h2>{{ $t('soluble-drinks.first-colomn.title') }}</h2>
            <h1>{{ $t('soluble-drinks.first-colomn.desc') }}</h1>
            <p>{{ $t('soluble-drinks.first-colomn.desc2') }}</p>
          </div>
        </div>

        <div class="img-and-text-right">
          <div>
            <h2>{{ $t('soluble-drinks.first-colomn.title2') }}</h2>
            <h1>{{ $t('soluble-drinks.first-colomn.desc3') }}</h1>
            <p>{{ $t('soluble-drinks.first-colomn.desc4') }}</p>
          </div>
          <img src="../../assets/img/soluble-drinks/drink.png" />
        </div>

        <div class="img-and-text-left">
          <img src="../../assets/img/soluble-drinks/spetema.png" />

          <div>
            <h2>{{ $t('soluble-drinks.first-colomn.title3') }}</h2>
            <h1>{{ $t('soluble-drinks.first-colomn.desc5') }}</h1>
            <p>{{ $t('soluble-drinks.first-colomn.desc6') }}</p>
          </div>
        </div>
      </section>

      <section class="packs">
        <h2>{{ $t('soluble-drinks.first-colomn.desc7') }}</h2>

        <div class="packs__container" :class="{ packs__active: activeTwo }" ref="packagesContainer">
          <div>
            <img src="../../assets/img/soluble-drinks/1.png" />
            <!-- <p class="title">{{ $t('soluble-drinks.second-colomn.title') }}</p> -->
            <p>{{ $t('soluble-drinks.second-colomn.desc') }}</p>
          </div>
          <div>
            <img src="../../assets/img/soluble-drinks/2.png" />
            <!-- <p class="title">{{ $t('soluble-drinks.second-colomn.title') }}</p> -->
            <p>{{ $t('soluble-drinks.second-colomn.desc2') }}</p>
          </div>
          <div>
            <img src="../../assets/img/soluble-drinks/3.png" />
            <!-- <p class="title">{{ $t('soluble-drinks.second-colomn.title') }}</p> -->
            <p>{{ $t('soluble-drinks.second-colomn.desc3') }}</p>
          </div>
        </div>
      </section>
      </div>
    </main>
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
export default {
    data() {
    return {
      active: false,
      activeTwo:false,
    };
  },
  methods: {
    isVisible() {
      const wrapper = this.$refs.logosContainer.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window

      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.active = true;
        });
      }
    },
    isVisiblePackages() {
      const wrapper = this.$refs.packagesContainer.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window

      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisiblePackages);
        setTimeout(() => {
          this.activeTwo = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
    document.addEventListener('scroll', this.isVisiblePackages);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
    document.removeEventListener('scroll', this.isVisiblePackages);
  },
  components: {
    HomeVideo,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/capsules.scss';
.soluble__drinks__container {
  color: #444444;
  max-width: $defaultWidth;
  margin: 0 auto;
  text-align: center;
  .parent{
    margin: 0 60px;
  }
  .text {
    margin: 5vh 0;

    h1 {
      margin: 2vh 0;
    }
    p {
      margin: 3vh 0;
      font: normal normal 600 18px/22px IBM Plex Sans;
    }
  }

  .logos {
    display: flex;
    overflow-x: scroll;

    div {
      flex-basis: 33%;

      img {
        padding-bottom: 2vh;
      }
      h1 {
        font: normal normal 800 24px/29px Raleway;
        letter-spacing: 2.4px;
      }
    }
  }
}
@media all and(max-width: $xsm) {
  .soluble__drinks__container {
  .parent{
    margin: 0 20px;
  }
  }
  .cup-and-cap-right {
    display: flex;
    flex-direction: column;
    text-align: center;

    div {
      margin-left: 5vh;
    }
    img {
      width: 100%;
    }
  }
  .cup-and-cap-left {
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
    }
  }

    .logos {
      div {
        margin: 2vh 5vh;
      }
      &.logos__active {
        animation: 1.5s ease-in 0.5s alternate-reverse both slidein;
      }

    }
        @keyframes slidein {
          30% {
            margin-left: -15vh;
          }
        }
}
</style>