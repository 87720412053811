<template>
  <div>
    <HomeVideo
      title="NESPRESSO®"
      title2="СЪВМЕСТИМИ КАПСУЛИ"
      subtitle="ИЗБЕРЕТЕ НИ ЗА ВАШАТА СОБСТВЕНА МАРКА КАФЕ"
      source="https://www.youtube.com/embed/hBUMr7ZKyKY?controls=0&autoplay=1&playlist=hBUMr7ZKyKY&loop=1&mute=1"
    />
    <!-- source for capsules-and-pods, nespresso, dolce-gusto, lavazza -->
    <main class="nespresso__container">
      <div class="parent">

      <div class="text">
        <h2>{{ $t('capsules-and-pods.nespresso.title') }}</h2>
        <p>{{ $t('capsules-and-pods.nespresso.desc') }}</p>
        <p>{{ $t('capsules-and-pods.nespresso.desc2') }}</p>
      </div>

      <section class="packs">
        <h2>{{ $t('capsules-and-pods.nespresso.third-colomn.title') }}</h2>
        <div class="packs__container" :class="{ packs__active: activeTwo }" ref="packagesContainer">
          <div>
            <img src="../../assets/img/capsules-and-pods/Nespresso/nes1.png" />
            <p>{{ $t('capsules-and-pods.nespresso.third-colomn.desc') }}</p>
          </div>

          <div>
            <img src="../../assets/img/capsules-and-pods/Nespresso/nes2.png" />
            <p>{{ $t('capsules-and-pods.nespresso.third-colomn.desc2') }}</p>
          </div>
        </div>
      </section>

      </div>

    </main>
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
export default {
  data() {
    return {
      active: false,
      activeTwo: false,
    };
  },
  methods: {
    // isVisible() {
    //   const wrapper = this.$refs.capsulesContainer.getBoundingClientRect();
    //   // checking if the whole element is between top and bottom of the window

    //   if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
    //     document.removeEventListener('scroll', this.isVisible);
    //     setTimeout(() => {
    //       this.active = true;
    //     });
    //   }
    // },
    isVisiblePackages() {
      const wrapper = this.$refs.packagesContainer.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window

      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisiblePackages);
        setTimeout(() => {
          this.activeTwo = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
    document.addEventListener('scroll', this.isVisiblePackages);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
    document.removeEventListener('scroll', this.isVisiblePackages);
  },
  components: {
    HomeVideo,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/capsules.scss';

$mainColor: transparent linear-gradient(180deg, #e5edf0 0%, #eef2f4 100%) 0% 0% no-repeat padding-box;
.nespresso__container {
  color: #444444;
  max-width: $defaultWidth;
  text-align: center;
    margin: 0 auto;

  .parent{
    margin: 0 60px;
  }
  .text {
    margin: 5vh 0;

    h1 {
      margin: 2vh 0;
    }
    p {
      margin: 3vh 0;
    }
  }
  .capsules {
    display: flex;
    overflow-x: scroll;

    div {
      margin: 0 1vh;

      img {
        background: $mainColorCapsules;
        border-radius: 10px;
        mix-blend-mode: darken;
      }
    }
  }
  .recycle {
    h2 {
      margin-bottom: 5vh;
    }
    img {
      margin-bottom: 5vh;
      width: 100%;
    }
    main {
      .logos_container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        overflow-x: scroll;
        div {
          flex-basis: 30%;
          margin-bottom: 3vh;
          img {
            width: fit-content;
            //object-fit: scale-down;
          }
        }
      }
    }
  }
}
@media all and(max-width: $xsm) {
  .nespresso__container {
    .capsules {
      &.capsules__active {
        animation: 1.5s ease-in 0.5s alternate-reverse both slidein;
      }
      @keyframes slidein {
        30% {
          margin-left: -15vh;
        }
      }
    }

    .logos_container {
      div {
        width: 100%;
        margin: 3vh 5vh;
      }
    }
  }
}
</style>