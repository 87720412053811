<template>
  <div class="balkam-group">
    <header class="main-header" :style="{ backgroundImage: `url(${require('@/assets/img/balkam-group/header.png')})` }">
      <h1>{{ $t('balkam-group.title') }}</h1>
    </header>
      <AboutBalkam />
      
  </div>
</template>

<script>
import AboutBalkam from './AboutBalkam';

export default {
  components:{
    AboutBalkam
  }
};
</script>

<style>

</style>