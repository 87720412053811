<template>
  <span ref="n">{{ number }}</span>
</template>

<script>
export default {
  props: ['n', 't', 'timeout'],
  computed: {
    number() {
      return Math.round(this.x);
    },
  },
  data() {
    return {
      x: 0, // counter
    };
  },
  methods: {
    animate() {
      const t = this.t || 3000; // passed time or default
      let ms = t / this.n;
      if (ms < 4) ms = 4; // minimum ms
      const repeats = t / ms;
      const step = this.n / repeats;

      const interval = setInterval(() => {
        if (this.x < this.n) {
          this.x += step;
        } else {
          this.x = this.n;
          clearInterval(interval);
        }
      }, ms);
    },
    isVisible() {
      const wrapper = this.$refs.n.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window
      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.animate();
        }, 1000 + (this.timeout || 0));
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
};
</script>

<style>
</style>