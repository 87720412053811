<template>
  <!-- <router-link class="brand__container" :class="{ right: right }" :to="link">
    <div class="brand__box" :style="{ backgroundColor: color }">
      <div class="underline"></div>
      <img class="product__img" :src="imageSourceProduct" alt="brand" />
      <div class="brand__text">
        <img class="logo__img" :src="imageSource" alt="brand" />
        <h2>{{ title }}</h2>

        <img class="product__img mobile" :src="imageSourceProduct" alt="brand" />
        <Button class="btn" type="button">{{ $t('buttons.button5') }}</Button>
      </div>
    </div>
  </router-link> -->
  <router-link class="test__container" :to="link">
    <div class="test__box">
      <img class="product__img" :src="imageSourceProduct" alt="brand" />
      <img class="logo__img" :src="imageSource" alt="brand" />
    </div>
  </router-link>
</template>

<script>
//import Button from '@/components/Button';
export default {
  props: ['imageSource', 'imageSourceProduct', 'color', 'title', 'link', 'right'],
  components: {
    //Button,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
//not confirmed
.test__container {
  .test__box {
    border-radius: 10px;
    background: #F1F1F1;
    display: flex;
    @include tr();
    height: 250px;

    img {
      width: 45%;
      @include tr();
    }
      :nth-child(1) {
        margin-left: -7%;
        object-fit: scale-down;
        width: 55%;
      }
    &:hover {
      transform: scale(0.95);
      :nth-child(1) {
        transform: scale(1.2);
      }
      :nth-child(2) {
        margin-left: 7%;
      }
    }
  }
}
//not confirmed
// .brand__container {
//   color: #ffffff;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   margin-top: 50px;

//   .underline {
//     width: 80%;
//     border-bottom: 2px solid white;
//     align-self: center;
//     position: absolute;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     z-index: 1;
//   }

//   .brand__box {
//     align-items: center;
//     display: flex;
//     justify-content: center;
//     height: 33vh;
//     margin: 10px 0;
//     width: 100%;
//     @include tr();
//     transition-timing-function: ease-out;

//     .product__img {
//       width: 19%;
//       left: 25%;
//       position: absolute;
//       z-index: 2;
//       @include tr();
//       transition-timing-function: ease-out;
//     }

//     .brand__text {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       z-index: 3;

//       @include tr();
//       transition-timing-function: ease-out;

//       h2 {
//         align-self: center;
//         padding-bottom: 20px;
//         font-size: 25px;
//       }

//       .logo__img {
//         width: 55%;
//         align-self: center;
//       }
//       .btn {
//         width: 20%;
//         align-self: center;
//       }

//       .product__img {
//         &.mobile {
//           display: none;
//         }
//       }
//     }
//   }

//   &.right {
//     .brand__box {
//       .product__img {
//         left: initial;
//         right: 25%;
//       }
//     }
//   }

//   @media all and (min-width: 1141px) {
//     .brand__box {
//       &:hover {
//         @include transform(scale(0.95, 1.2));

//         .product__img {
//           @include transform(scale(1.3, 1.1));
//         }
//         .brand__text {
//           @include transform(scale(1, 0.75));
//         }

//         // .btn {
//         //   background-color: transparent;
//         //   color: transparent;
//         //   color: #000;
//         //   @include transform(scale(1, 1));
//         // }
//       }
//     }
//   }
// }

// @media all and (max-width: $xsm) {
//   .brand__container {
//     display: flex;
//     justify-content: center;
//     margin-top: 0;

//     .underline {
//       display: none;
//     }
//     .brand__box {
//       display: flex;
//       flex-direction: column;
//       height: auto;
//       margin-top: 0;

//       .product__img {
//         display: none;
//       }

//       .brand__text {
//         .product__img {
//           display: block;
//           position: initial;
//           &.mobile {
//             display: block;
//             width: 100%;
//           }
//         }

//         .logo__img {
//           width: 70%;
//           border-bottom: 2px solid white;
//         }

//         h2 {
//           text-align: center;
//           padding-bottom: 0;
//         }

//         .btn {
//           margin-bottom: 20px;
//         }
//       }
//     }
//   }
// }
</style>