<template>
  <div class="home">
    <HomeVideo
      source="https://www.youtube.com/embed/FHs65ptPAFw?controls=0&autoplay=1&playlist=FHs65ptPAFw&loop=1&mute=1"
    />
    <!-- source for partnership and homepage -->
    <AboutBalkam />
    <MainActivities />
    <WhyBalkam />
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
import AboutBalkam from './AboutBalkam';
import MainActivities from './MainActivities';
import WhyBalkam from './WhyBalkam';
export default {
  name: 'Home',
  components: {
    HomeVideo,
    AboutBalkam,
    MainActivities,
    WhyBalkam,
  },
};
</script>
