<template>
  <div class="mobile__container">
    <a class="hdr__nv__lng" @click="changeLocaleBg">
      <span class="hdr__nv__lng__hdn">
        <span class="hdr__nv__lng__wrp" :class="{ 'hdr__nv__lng__wrp--bg': $i18n.locale === 'bg' }">
          <span>БЪЛГАРСКИ</span>
        </span>
      </span>
    </a>
    <a class="hdr__nv__lng__En" @click="changeLocaleEn">
      <span class="hdr__nv__lng__hdn">
        <span class="hdr__nv__lng__wrp" :class="{ 'hdr__nv__lng__wrp--en': $i18n.locale === 'en' }">
          <span>ENGLISH</span>
        </span>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    changeLocaleEn() {
      this.$i18n.locale = 'en';
      localStorage.setItem('locale', this.$i18n.locale);
    },
    changeLocaleBg() {
      this.$i18n.locale = 'bg';
      localStorage.setItem('locale', this.$i18n.locale);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';

@media all and (max-width: $xsm) {
  .mobile__container {
    display: flex;
    padding-top: 1vh;
    
    
    .hdr__nv__lng {
      align-items: center;
      color: #000;
      cursor: pointer;
      display: flex;
      height: 30px;
      justify-content: center;
      position: relative;
      width: 45vw;
      border-radius: 4px;

      @include transition($transition);
      @include noselect();

      .hdr__nv__lng__hdn {
        height: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
      }
      .hdr__nv__lng__wrp {
        height: 200%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        span {
          align-items: center;
          display: flex;
          height: 50%;
          justify-content: center;
          width: 100%;
        }
      }

      &::before {
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        background-color: rgba($color: #ffffff, $alpha: 0.6);
        border-radius: 10px;
        border-radius: 4px;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        transition: inherit;
        width: 100%;
      }
    }

    .hdr__nv__lng__En {
      align-items: center;
      color: #000;
      cursor: pointer;
      display: flex;
      height: 30px;
      justify-content: center;
      position: relative;
      width: 45vw;
      border-radius: 4px;
      margin-left: 10px;

      @include transition($transition);
      @include noselect();

      .hdr__nv__lng__hdn {
        height: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        width: 100%;
      }
      .hdr__nv__lng__wrp {
        height: 200%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        span {
          align-items: center;
          display: flex;
          height: 50%;
          justify-content: center;
          width: 100%;
        }
      }

      &::before {
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        background-color: rgba($color: #ffffff, $alpha: 0.6);
        border-radius: 10px;
        border-radius: 4px;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        transition: inherit;
        width: 100%;
      }
    }
  }
}
</style>