<template>
  <div>
    <HomeVideo title="МЛЯНО КАФЕ" title2="" subtitle="ИЗБЕРЕТЕ НИ ЗА ВАШАТА СОБСТВЕНА МАРКА КАФЕ" 
     source="https://www.youtube.com/embed/hBUMr7ZKyKY?controls=0&autoplay=1&playlist=hBUMr7ZKyKY&loop=1&mute=1"
    />
    <!-- source for grinded video-->
    <main class="grinded__container">
      <div class="parent">
      <div class="text">
        <h2>{{ $t('grinded.title') }}</h2>
      </div>

      <section class="img-and-text-right">
        <div>
          <h2>{{ $t('grinded.first-colomn.title') }}</h2>
          <h1>{{ $t('grinded.first-colomn.desc') }}</h1>
          <p>{{ $t('grinded.first-colomn.desc2') }}</p>
          <p>{{ $t('grinded.first-colomn.desc3') }}</p>
        </div>

        <img src="../../assets/img/grinded/grinded.png" />
      </section>

      <section class="packs">
        <h2>{{ $t('grinded.title2') }}</h2>

        <div class="packs__container" :class="{ packs__active: active }" ref="packagesContainer">
          <div>
            <img src="../../assets/img/grinded/1.png" />
            <p>{{ $t('grinded.second-colomn.desc') }}</p>
          </div>
          <div>
            <img src="../../assets/img/grinded/2.png" />
            <p>{{ $t('grinded.second-colomn.desc2') }}</p>
          </div>
          <div>
            <img src="../../assets/img/grinded/3.png" />
            <p>{{ $t('grinded.second-colomn.desc3') }}</p>
          </div>
        </div>
      </section>
      </div>
    </main>
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    isVisible() {
      const wrapper = this.$refs.packagesContainer.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window

      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.active = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
  components: {
    HomeVideo,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/capsules.scss';
.grinded__container {
  color: #444444;
  max-width: $defaultWidth;
  margin: 0 auto;
  text-align: center;

.parent{
  margin: 0 60px;
}
  .text {
    margin: 5vh 0;

    h1 {
      margin: 2vh 0;
    }
    p {
      margin: 3vh 0;
    }
  }
}
@media all and(max-width:$xsm){
  .grinded__container {
.parent{
  margin: 0 20px;
}
  }
}
</style>