<template>
  <div class="marketing">
    <header class="main-header" :style="{ backgroundImage: `url(${require('@/assets/img/contacts/header.png')})` }">
      <h1>{{ $t('marketing.title') }}</h1>
    </header>
   <Distribution />
  </div>
</template>

<script>
import Distribution from './Distribution.vue';

export default {
  components: {
    Distribution
  }
};
</script>

<style>
</style>