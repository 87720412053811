<template>
  <div class="home__about">
    <div class="texts">
      <h2>{{ $t('home.aboutBalkam.title') }}</h2>
      <p>{{ $t('home.aboutBalkam.desc1') }}</p>
      <p>{{ $t('home.aboutBalkam.desc2') }}</p>
      <p>{{ $t('home.aboutBalkam.desc3') }}</p>
    </div>
    <img src="@/assets/img/home/about-us-home.png" alt="Factory" />
    <router-link to="/balkam-group">
      <Button type="button">{{ $t('buttons.button1') }}</Button>
    </router-link>
  </div>
</template>

<script>
import Button from '@/components/Button';
export default {
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.home__about {
  position: relative;
  text-align: center;

  .texts {
    margin: 20px auto;
    position: relative;
    z-index: 1;
  }
  > img {
    margin-top: -5%;
    width: 100%;
  }
  .btn {
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
@media screen and(max-width:$s) {
  .home__about {
    .texts {
      padding: 20px;
    }
  }
}
</style>