<template>
  <div>
    <HomeVideo title="LAVAZZA® BLUE®" title2="СЪВМЕСТИМИ КАПСУЛИ" subtitle="ИЗБЕРЕТЕ НИ ЗА ВАШАТА СОБСТВЕНА МАРКА КАФЕ" 
     source="https://www.youtube.com/embed/hBUMr7ZKyKY?controls=0&autoplay=1&playlist=hBUMr7ZKyKY&loop=1&mute=1"
    />
<!-- source for capsules-and-pods, nespresso, dolce-gusto, lavazza -->

    <main class="lavazza_container">
      <div class="parent">

      <div class="text">
        <h2>{{ $t('capsules-and-pods.lavazza.title') }}</h2>
        <p>{{ $t('capsules-and-pods.lavazza.desc') }}</p>
      </div>

      <section class="img-and-text-left">
        <img src="../../assets/img/capsules-and-pods/Lavazza/first-picture.png" />

        <div>
          <h2>{{ $t('capsules-and-pods.lavazza.first-colomn.title') }}</h2>
          <h1>{{ $t('capsules-and-pods.lavazza.first-colomn.desc') }}</h1>
          <p>{{ $t('capsules-and-pods.lavazza.first-colomn.desc2') }}</p>
          <p>{{ $t('capsules-and-pods.lavazza.first-colomn.desc3') }}</p>
          <p>{{ $t('capsules-and-pods.lavazza.first-colomn.desc4') }}</p>
        </div>
      </section>

      <section class="packs">
        <h2>{{ $t('capsules-and-pods.lavazza.first-colomn.title2') }}</h2>
        <div class="packs__container">
        <div>
          <img src="../../assets/img/capsules-and-pods/Lavazza/l16.png" />
          <p>{{ $t('capsules-and-pods.lavazza.first-colomn.desc2') }}</p>
        </div>
        <div>
          <img src="../../assets/img/capsules-and-pods/Lavazza/l50.png" />
          <p>{{ $t('capsules-and-pods.lavazza.first-colomn.desc3') }}</p>
        </div>
        <div>
          <img src="../../assets/img/capsules-and-pods/Lavazza/l100.png" />
          <p>{{ $t('capsules-and-pods.lavazza.first-colomn.desc4') }}</p>
        </div>
        </div>
      </section>
      </div>
    </main>
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
export default {
  components: {
    HomeVideo,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/capsules.scss';

.lavazza_container {
  color: #444444;
  max-width: $defaultWidth;
  margin: 0 auto;
  text-align: center;

.parent{
  margin: 0 60px;
}
  .text {
    margin: 5vh 0;

    h1 {
      margin: 2vh 0;
    }
    p {
      margin: 3vh 0;
    }
  }

  .package {
    div {
      overflow-x: hidden;
      position: relative;
      color: white;
      width: 100%;
    }
    h2 {
      margin: 5vh 0;
    }

        p {
        font-size: 20px;
        font-weight: 600;
        left: 50%;
        position: absolute;
        top: 90%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        width: 90%;
      }
  }
  ::-webkit-scrollbar {
    width: 0;
  }
}
@media all and(max-width: $xsm) {
  .lavazza_container {
.parent{
  margin: 0 20px;
}
  }
  .package {
    img {
      width: 100%;
    }
  }
}
</style>