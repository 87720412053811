import Vue from 'vue';
import App from './App.vue';
import router from './router';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueI18n from 'vue-i18n';
import Snotify from 'vue-snotify';
import VueTilt from 'vue-tilt.js'

Vue.use(VueTilt)
Vue.use(VueI18n);
Vue.use (Snotify, {
  toast: {
    position: 'rightBottom',
    showProgressBar: false,
    timeout: 3000
  }
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCLkz79wB1nVvq4joZf49twSs-U-DTRD3M',
    libraries: "places"
  },
});

const messages = {
  bg: require('./locale/bg.json'),
  en: require('./locale/en.json'),
}

const i18n = new VueI18n({
  locale: 'en',
  messages
});

const savedLocale = localStorage.getItem('locale');

if (!savedLocale) {
  i18n.locale = 'bg'
} else {
  i18n.locale = savedLocale;
}

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')