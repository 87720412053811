<template>
  <div>
    <HomeVideo
      title="ЗА ВАШ ПАРТНЬОР"
      title2=""
      subtitle="ИЗБЕРЕТЕ НАС"
      source="https://www.youtube.com/embed/FHs65ptPAFw?controls=0&autoplay=1&playlist=FHs65ptPAFw&loop=1&mute=1"
    />
    <!-- source for partnership and homepage -->
    <div class="partnership__container">
      <main>
        <div class="box">
          <div class="texts">
            <h2 class="center">{{ $t('partnership.title') }}</h2>
          </div>
          <div class="box__clm">
            <div class="texts">
              <p>{{ $t('partnership.p') }}</p>
              <p>{{ $t('partnership.p1') }}</p>
            </div>

            <div class="right__clm">
              <img src="@/assets/img/capsules-and-pods/partnership/packs.png" alt="" />
            </div>
          </div>
        </div>
        <div class="texts">
          <h2 class="center">{{ $t('partnership.productstitle') }}</h2>
          <p class="center">{{ $t('partnership.productstitle3') }}</p>
          <p class="center"></p>
        </div>
        <OurRange />
        <div class="texts">
          <h2 class="center">{{ $t('partnership.productstitle1') }}</h2>
          <p class="center">{{ $t('partnership.productstitle2') }}</p>
          <p class="center"></p>
        </div>
        <section class="choose-container">
          <main class="choose-parent" ref="boxContainer">
            <div class="element__box">
              <router-link :class="{ rl__active: active }" to="/coffee-beans" class="rl">
                <img src="@/assets/img/capsules-and-pods/partnership/coffee-beans.png" alt="" />
                <div class="gradient"></div>
                <div class="text">
                  <p>{{ $t('partnership.desc6') }}</p>
                  <span>{{ $t('partnership.subtitle') }}</span>
                </div>
              </router-link>
            </div>

            <div class="element__box">
              <router-link to="/grinded" class="rl">
                <img src="@/assets/img/capsules-and-pods/partnership/coffee-grinded.png" alt="" />
                <div class="gradient"></div>
                <div class="text">
                  <p>{{ $t('partnership.desc7') }}</p>
                  <span>{{ $t('partnership.subtitle2') }}</span>
                </div>
              </router-link>
            </div>

            <div class="element__box">
              <router-link to="/soluble-drinks" class="rl">
                <img src="@/assets/img/capsules-and-pods/partnership/soluble.png" alt="" />
                <div class="gradient"></div>
                <div class="text">
                  <p>{{ $t('partnership.desc8') }}</p>
                  <span>{{ $t('partnership.subtitle3') }}</span>
                </div>
              </router-link>
            </div>
          </main>
        </section>

        <div class="texts" ref="vending">
          <h2 class="center">{{ $t('partnership.vending.title') }}</h2>
        </div>

        <section class="choose-container">
          <main class="choose-parent vending">
            <div>
              <h1 class="center">{{ $t('partnership.vending.bianchi') }}</h1> 
              <img src="@/assets/img/capsules-and-pods/partnership/bianci.png" alt="" />
              <span class="center">{{ $t('partnership.vending.bianchi-desc') }}</span>
            </div>
            <div>
              <h1 class="center">{{ $t('partnership.vending.necta') }}</h1>
              <img src="@/assets/img/capsules-and-pods/partnership/necta.png" alt="" />
              <span class="center">{{ $t('partnership.vending.necta-desc') }}</span>
            </div>
          </main>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
import OurRange from './OurRange';
export default {
  watch: {
    $route: { handler: 'scrollTo' },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    scrollTo() {
      if (this.$route.query.scr) {
        const element = this.$refs[this.$route.query.scr];
        this.$nextTick(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        });
      }
    },
    isVisible() {
      const wrapper = this.$refs.boxContainer.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window

      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.active = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
    this.scrollTo();
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
  components: {
    HomeVideo,
    OurRange,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.choose-container {
  padding: 0 $sides;
  .choose-parent {
    background: transparent linear-gradient(180deg, #e5edf0 0%, #eef2f4 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    display: flex;
    margin: 0 auto;
    padding: 20px;
    gap: 15px;
    scroll-snap-type: x mandatory;
    width: 100%;
    .element__box {
      width: 100%;
      .rl {
        display: flex;
        height: 300px;
        justify-items: center;
        position: relative;

        img {
          border-radius: 20px;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }

        .gradient {
          background: transparent linear-gradient(180deg, #00000000 0%, #00000000 15%, #000000 100%) 0% 0% no-repeat
            padding-box;
          border-radius: 20px;
          height: 100%;
          position: absolute;
          width: 100%;
        }
        .text {
          bottom: 0;
          align-self: flex-end;
          color: white;
          left: 0;
          position: relative;
          padding: 17px 10px;
          position: absolute;
          text-align: center;
          width: 100%;
          p {
            font-weight: 600;
            font-size: 26px;
            padding-bottom: 10px;
          }
          span {
            font-size: 15px;
          }
        }
      }
    }
    &.vending {
      text-align: center;
      div {
        flex-basis: 50%;
        img {
          height: 500px;
          object-fit: scale-down;
          margin: 20px 0;
          width: 100%;
        }
      }
    }
  }
}

.texts {
  h2 {
    margin: 34px 0;
  }

  @media screen and (max-width: $xsm) {
    & {
      text-align: center;
    }
  }
}

.box {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 0;
  overflow: hidden;
  padding: 0.1px $sides 15px $sides;
  > .texts {
    padding: 0;
  }
}

.img-and-text-left-partnership {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 20px;
  .images {
    flex-basis: 100%;
    position: relative;
    margin-top: -55%;
    z-index: -1;
    img {
      width: 100%;
    }
  }

  .texts {
    flex-basis: 100%;
    padding: 0;
  }
}

.partnership__container {
  overflow-x: hidden;
  margin: 0 auto;
  main {
    max-width: $maxWidthWithSides;
    margin: 0 auto;
    @media screen and (max-width: $xsm) {
      .partnership__container {
        padding: 0 1.5em 15px 1.5em;
      }
      .box {
        padding: 0;
        background-image: initial;
      }
      .img-and-text-left-partnership {
        display: block;

        div {
          flex-basis: 100%;
          margin-left: 0vh;
          padding: 0 $sides;
        }
      }
    }
    @media all and(max-width: $xsm) {
      .choose-container {
        padding: 0;
        .choose-parent {
          border-radius: 0px;
          scroll-snap-type: x mandatory;
          overflow: scroll;
          gap: 0;
          padding-right: $sides;
          @include scrollbar(0px, #fff);

          .element__box {
            scroll-snap-align: start;
            padding-left: $sides;
            .rl {
              width: 85vw;

              &.rl__active {
                animation: 2s ease-in 0.5s alternate-reverse both slidein;
                @keyframes slidein {
                  30% {
                    margin-left: -120px;
                  }
                }
              }
            }
          }
          &.vending {
            flex-wrap: wrap;
            div{
              flex-basis: 100%;
            }
          }
        }
      }
    }
    .box__clm {
      display: flex;
      gap: 20px;
      height: auto;
      justify-content: center;

      .texts {
        flex-basis: 50%;
      }

      .right__clm {
        flex-basis: 50%;
        position: relative;
        img {
          border-radius: 10px;
          display: block;
          height: 100%;
          left: 0;
          object-fit: contain;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }

      @media screen and (max-width: $xsm) {
        & {
          flex-direction: column;
          .texts {
            padding: 0 $sides;
          }
        }
        .left__clm {
          flex-basis: 100%;
        }
        .right__clm {
          flex-basis: 100%;
          img {
            height: auto;
            position: static;
            width: 100%;
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0;
  }
}

@media screen and (max-width: $s) {
  .partnership__container {
    .img-and-text-left-partnership {
      .images {
        margin-top: -85vw;
        overflow: hidden;
        img {
          width: 175%;
          margin-left: -25%;
        }
      }
    }
  }
}
</style>
