<template>
  <div>
    <HomeVideo
      title="КАФЕ НА ЗЪРНА"
      title2=""
      subtitle="ИЗБЕРЕТЕ НИ ЗА ВАШАТА СОБСТВЕНА МАРКА КАФЕ"
      source="https://www.youtube.com/embed/f04_BLUx1xg?controls=0&autoplay=1&playlist=f04_BLUx1xg&loop=1&mute=1"
    />
    <!-- source for coffee-beans -->
    <main class="coffee__beans__container">
      <div class="parent">
        <div class="text">
          <h2>{{ $t('coffee-beans.title') }}</h2>
        </div>

        <section class="img-and-text-right">
          <div>
            <p>{{ $t('coffee-beans.first-colomn.title') }}</p>
            <p>{{ $t('coffee-beans.first-colomn.desc') }}</p>
          </div>

          <img src="../../assets/img/coffee-beans/coffee-beans.png" />
        </section>

        <section class="packs">
          <h2>{{ $t('coffee-beans.title2') }}</h2>

          <div class="packs__container" :class="{ packs__active: active }" ref="packagesContainer">
            <div>
              <img src="../../assets/img/coffee-beans/1.png" />
              <p>{{ $t('coffee-beans.second-colomn.desc') }}</p>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    isVisible() {
      const wrapper = this.$refs.packagesContainer.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window

      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.active = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
  components: {
    HomeVideo,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/capsules.scss';
.coffee__beans__container {
  color: #444444;
  max-width: $defaultWidth;
  margin: 0 auto;
  text-align: center;

  .parent {
    margin: 0 60px;
  }
  .text {
    margin: 5vh 0;

    h1 {
      margin: 2vh 0;
    }
    p {
      margin: 3vh 0;
    }
  }
}
@media all and(max-width:$xsm) {
  .coffee__beans__container {
    .parent {
      margin: 0 20px;
    }
  }
}
</style>