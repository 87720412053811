<template>
  <a class="hdr__nv__lng" @click="changeLocale">
    <span class="hdr__nv__lng__hdn">
      <span class="hdr__nv__lng__wrp" :class="{ 'hdr__nv__lng__wrp--bg': $i18n.locale === 'bg' }">
        <span>EN</span>
        <span>BG</span>
      </span>
    </span>
  </a>
</template>

<script>
export default {
  methods: {
    changeLocale() {
      this.$i18n.locale = this.$i18n.locale === 'en' ? 'bg' : 'en';
      localStorage.setItem('locale', this.$i18n.locale);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.hdr__nv__lng {
  align-items: center;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  justify-content: center;
  margin-left: 24px;
  position: relative;
  width: 30px;
  @include transition($transition);
  @include noselect();

  .hdr__nv__lng__hdn {
    height: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .hdr__nv__lng__wrp {
    height: 200%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    @include tr();
    &:hover:not(.hdr__nv__lng__wrp--bg),
    &.hdr__nv__lng__wrp--bg:not(:hover) {
      @include transform(translateY(-50%));
    }
    span {
      align-items: center;
      display: flex;
      height: 50%;
      justify-content: center;
      width: 100%;
    }
  }

  &::before {
    background-color: #fff;
    border-radius: 4px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    transition: inherit;
    width: 100%;
  }

  &:hover {
    &::before {
      @include transform(rotate(180deg));
    }
  }
}
</style>