<template>
  <div>
    <HomeVideo
      title="DOLCE GUSTO®"
      title2="СЪВМЕСТИМИ КАПСУЛИ"
      subtitle="ИЗБЕРЕТЕ НИ ЗА ВАШАТА СОБСТВЕНА МАРКА КАФЕ"
      source="https://www.youtube.com/embed/hBUMr7ZKyKY?controls=0&autoplay=1&playlist=hBUMr7ZKyKY&loop=1&mute=1"
    />
    <!-- source for capsules-and-pods, nespresso, dolce-gusto, lavazza -->
    <div class="dolce-gusto-main-container">
      <main class="dolce-gusto-container">
        <div class="text">
          <h2>{{ $t('capsules-and-pods.dolce-gusto.title') }}</h2>
          <p>{{ $t('capsules-and-pods.dolce-gusto.desc') }}</p>
        </div>

        <section>
          <h2>{{ $t('capsules-and-pods.dolce-gusto.first-colomn.title') }}</h2>
          <div class="capsules-container">
            <div
              class="container-item"
              :style="{
                backgroundImage: `url(${require('../../assets/img/capsules-and-pods/Dolce-Gusto/first-image.png')})`,
              }"
            >
              <div class="gradient"></div>
              <div class="text">
                <p>{{ $t('capsules-and-pods.dolce-gusto.first-colomn.desc') }}</p>
              </div>
            </div>
            <div class="container-item">
              <div class="gradient"></div>

              <div class="text">
                <p>{{ $t('capsules-and-pods.dolce-gusto.first-colomn.desc2') }}</p>
              </div>
            </div>
          </div>
        </section>

        <section class="img-and-text-left">
          <img src="../../assets/img/capsules-and-pods/Dolce-Gusto/third-image.png" />
          <div>
            <h2>{{ $t('capsules-and-pods.dolce-gusto.second-colomn.title') }}</h2>
            <p>{{ $t('capsules-and-pods.dolce-gusto.second-colomn.desc') }}</p>
          </div>
        </section>
        <section class="img-and-text-right">
          <div>
            <h2>{{ $t('capsules-and-pods.dolce-gusto.third-colomn.title') }}</h2>
            <p>{{ $t('capsules-and-pods.dolce-gusto.third-colomn.desc') }}</p>
            <p>{{ $t('capsules-and-pods.dolce-gusto.third-colomn.desc2') }}</p>
            <p>{{ $t('capsules-and-pods.dolce-gusto.third-colomn.desc3') }}</p>
            <p>{{ $t('capsules-and-pods.dolce-gusto.third-colomn.desc4') }}</p>
          </div>
          <img src="../../assets/img/capsules-and-pods/Dolce-Gusto/fourth-image.png" />
        </section>
        <section class="packs">
          <h2>{{ $t('capsules-and-pods.dolce-gusto.fourth-colomn.title') }}</h2>

          <div class="packs__container" :class="{ packs__active: active }" ref="packagesContainer">
            <div>
              <img src="../../assets/img/capsules-and-pods/Dolce-Gusto/dg16.png" />
              <p>{{ $t('capsules-and-pods.dolce-gusto.fourth-colomn.desc') }}</p>
            </div>
            <div>
              <img src="../../assets/img/capsules-and-pods/Dolce-Gusto/dg30.png" />
              <p>{{ $t('capsules-and-pods.dolce-gusto.fourth-colomn.desc2') }}</p>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    isVisible() {
      const wrapper = this.$refs.packagesContainer.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window

      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.active = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
  components: {
    HomeVideo,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/capsules.scss';

.capsules-container {
  display: flex;
  justify-content: space-around;
  padding: 5vh 0;
  gap: 30px;

  .container-item {
    background: url('../../assets/img/capsules-and-pods/Dolce-Gusto/second-image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 330px;
    flex-basis: 50%;
    border-radius: 15px;
    position: relative;

    .gradient {
      background: transparent linear-gradient(180deg, #00000000 0%, #00000000 65%, #000000 100%);
      border-radius: 15px;
      position: relative;
      height: 330px;
    }
    .text {
      color: white;
      text-align: center;
      position: absolute;
      bottom: -10%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
.dolce-gusto-main-container {
  margin: 0 auto;
  max-width: $defaultWidth;
}
.dolce-gusto-container {
  color: #444444;
  max-width: 1800px;
  margin: 0 60px;
  text-align: center;

  .text {
    margin: 5vh 0;

    h2 {
      margin: 3vh 0;
    }
    p {
      margin: 0vh 0;
    }
  }

}
@media all and(max-width: $xsm) {
  .capsules-container {
    flex-direction: column;
  }
  .dolce-gusto-container {
    margin: 0 20px;
    overflow-x: hidden;
  }
}
</style>