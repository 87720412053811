<template>
  <div class="whyBalkam__container">
    <div class="brands-main">
      <div class="texts">
        <h2>{{ $t('home.whyBalkam.title-brands') }}</h2>
      </div>
      <main class="brands-container">
        <section>
          <router-link
            :to="brand.route"
            class="logo-parent"
            :class="[brand.name]"
            v-for="brand in brands"
            :key="brand.route"
          >
            <div
              class="logo"
              :style="{
                backgroundImage: `url(${require(`@/assets/img/${brand.img}`)})`,
              }"
            ></div>
          </router-link>
        </section>
      </main>
    </div>

    <section class="whyBalkam-container-section">
      <div class="texts">
        <h2>{{ $t('home.whyBalkam.title') }}</h2>
        <p>{{ $t('home.whyBalkam.paragraph') }}</p>
      </div>
      <section>
        <main class="whyBalkam-container-main" ref="whyBalkamBox">
          <div
            class="whyBalkam__box__element"
            :class="{ whyBalkam__box__element__active: active }"
            :style="{
              backgroundImage: `url(${require('@/assets/img/home/s1.png')})`,
            }"
          >
            <div class="gradient"></div>
            <div class="text">
              <p>
                {{ $t('home.whyBalkam.title1') }}
              </p>
              <span class="box-subtitle">{{ $t('home.whyBalkam.desc') }}</span>
            </div>
          </div>

          <div
            class="whyBalkam__box__element"
            :style="{
              backgroundImage: `url(${require('@/assets/img/home/s2.png')})`,
            }"
          >
            <div class="gradient"></div>
            <div class="text">
              <p>
                {{ $t('home.whyBalkam.title2') }}
              </p>
              <span class="box-subtitle">{{ $t('home.whyBalkam.desc2') }} </span>
            </div>
          </div>

          <div
            class="whyBalkam__box__element"
            :style="{
              backgroundImage: `url(${require('@/assets/img/home/s3.png')})`,
            }"
          >
            <div class="gradient"></div>
            <div class="text">
              <p>
                {{ $t('home.whyBalkam.title3') }}
              </p>
              <span class="box-subtitle">{{ $t('home.whyBalkam.desc3') }} </span>
            </div>
          </div>
        </main>
        <footer>
          <router-link to="/contacts">
            <Button class="btn" type="button">{{ $t('buttons.button2') }}</Button>
          </router-link>
        </footer>
      </section>
    </section>
  </div>
</template>

<script>
import Button from '@/components/Button';
export default {
  data() {
    return {
      active: false,

      brands: [
        { img: 'home/spetema.svg', route: '/brandSpetema', name: 'spetema' },
        { img: 'home/balkam.svg', route: '/brandBalkam' },
        { img: 'home/santos.svg', route: '/brandSantos', name: 'santos' },
        { img: 'home/lavista.svg', route: '/brandLavista' },
        { img: 'home/napoli.svg', route: '/brandNapoli' },
        { img: 'home/verdico.svg', route: '/BrandVerdico' },
        { img: 'brands/caprimo/caprimo.png', route: '/BrandCaprimo', name: 'caprimo' },
        { img: 'brands/vanHouten/vanHouten.png', route: '/BrandVanHouten', name: 'vanHouten' },
        { img: 'brands/maison/maison-small.svg', route: '/BrandMaison', name: 'routin' },
        { img: 'brands/te/te-small.svg', route: '/BrandTe', name: 'tea' },
        { img: 'brands/basilur/basilur-small.svg', route: '/BrandBasilur', name: 'tea' },
        { img: 'brands/greenIsland/greenIsland-small.svg', route: '/BrandGreenIsland', name: 'tea' },
      ],
    };
  },
  methods: {
    isVisible() {
      const wrapper = this.$refs.whyBalkamBox.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window
      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.active = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
$background-color: #e9ded1;
.brands-main {
  background: transparent linear-gradient(180deg, #e5edf0 0%, #eef2f4 100%) 0% 0% no-repeat padding-box;
  padding: 45px 0 20px 0;

  .texts {
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .brands-container {
    max-width: 1800px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    //gap: 50px;
    flex-direction: column;

    section {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 1100px;
      gap: 10px 50px;
    }
    .logo-parent {
      background-size: 0%;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 30px;
      height: 130px;
      width: 12%;
      transition: all 0.7s;
      position: relative;

      &::before {
        content: '';
        background-image: url('../../assets/img/home/cup.png');
        width: 0;
        height: 0;
        left: 50%;
        top: 50%;
        position: absolute;
        background-size: 100%;
        transition: all 0.7s;
        @include transform(translateX(-50%) translateY(-45%));
      }
      &.spetema {
        .logo {
          height: 130px;
          transform: scale(1.2);
        }
      }
      &.santos {
        .logo {
          transform: scale(1.5);
        }
      }
      &.vanHouten,
      &.caprimo {
        &::before {
          content: '';
          background-image: url('../../assets/img/home/cup2.png');
        }
      }
      &.tea {
        &::before {
          content: '';
          background-image: url('../../assets/img/home/cup3.png');
        }
      }
      &.routin {
        &::before {
          content: '';
          background-image: url('../../assets/img/home/cup4.png');
        }
      }
      .logo {
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        height: 130px;
        width: 130px;
        position: relative;
        z-index: 1;
      }

      &:hover {
        &::before {
          width: 200%;
          height: 200%;
        }
      }
    }
  }
}
.whyBalkam-container-section {
  margin: 0 auto;
  position: relative;

  .texts {
    margin: 20px auto;
  }

  section {
    background: transparent linear-gradient(180deg, #e5edf0 0%, #eef2f4 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin: 20px auto 0;
    overflow: hidden;
    max-width: $maxWidth;
    padding: 0 30px 30px;
    width: calc(100% - (2 * #{$sides}));
  }

  .whyBalkam-container-main {
    display: flex;
    .whyBalkam__box__element {
      background-size: cover;
      background-position: center;
      border-radius: 20px;
      height: 300px;
      margin: 30px 0px;
      position: relative;
      width: 33%;

      &:not(:last-child) {
        margin-right: 30px;
      }

      .gradient {
        background: transparent linear-gradient(180deg, #00000000 0%, #00000000 65%, #000000 100%) 0% 0% no-repeat
          padding-box;
        border-radius: 20px;
        height: 300px;
      }
    }
    .text {
      bottom: 0%;
      color: white;
      left: 0%;
      position: absolute;
      text-align: center;
      width: 100%;
      padding: 20px;
      p {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      span {
        font-size: 15px;
      }
    }
    footer {
      width: 100%;
    }
  }
}
@media all and(max-width: $xsm) {
  .brands-main {
    .brands-container {
      section {
        gap: 10px;
      }
      .logo-parent {
        margin: 0 auto;
        flex-basis: 40%;
        .logo {
          margin: 0 auto;
        }

        &:hover {
          &::before {
            width: 0;
            height: 0;
          }
        }
      }
    }
  }
  .whyBalkam-container-section {
    section {
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
    overflow-x: hidden;
    .whyBalkam-container-main {
      border-radius: 0;
      overflow: auto;
      margin: 0vh;
      padding-left: $sides;
      scroll-snap-type: x mandatory;
      width: 100%;
      @include scrollbar(0, #ffffff);
      .whyBalkam__box__element {
        height: 300px;
        scroll-snap-align: start;
        width: 85vw;

        .gradient {
          height: 300px;
          width: 85vw;
        }

        &.whyBalkam__box__element__active {
          animation: 2s ease-in 0.5s alternate-reverse both slidein;

          @keyframes slidein {
            30% {
              margin-left: -120px;
            }
          }
        }
      }
    }
    .btn {
      width: 90%;
    }
  }
  ::-webkit-scrollbar {
    width: 0;
  }
}
@media all and(max-width:400px) {
  .whyBalkam-container-section {
    .whyBalkam-container-main {
      .whyBalkam__box__element {
        .gradient {
          background: transparent linear-gradient(#00000000 0%, #00000000 10%, #000000 100%) 0% 0% no-repeat;
        }
        .text {
          // top: 55%;
          p {
            font-size: 25px;
          }
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}
.whyBalkam__container {
  text-align: center;
  color: #444444;

  .verdico {
    width: 130px;
    height: 130px;
  }
  .brands {
    background: rgb(238, 242, 244);
    background: linear-gradient(0deg, rgba(238, 242, 244, 1) 0%, rgba(229, 237, 240, 1) 100%);
    padding-bottom: 3px;

    h1 {
      padding-top: 25px;
      margin-bottom: 20px;
    }

    li {
      display: inline-block;
      padding: 20px 20px;
      @include tr();
      z-index: 0;

      &:hover {
        background: transparent url('../../assets/img/home/cup.png') 0% 0% no-repeat padding-box;
        background-size: cover;
        @include transform(scale(1.5));
        z-index: 0;
      }
    }
  }
}
</style>
