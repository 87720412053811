<template>
  <main>
    <div class="container" ref="boxContainer">
      <main class="main_box" :class="{ main_box__active: active }">
        <div class="image">
          <img src="@/assets/img/balkam-group/propr/1.jpg" alt="" />
          <div class="gradient"></div>
        </div>
        <div class="text">
          <p>{{ $t('balkam-group.cycle.desc') }}</p>
        </div>
      </main>

      <main class="main_box">
        <div class="image">
          <img src="@/assets/img/balkam-group/propr/2.jpg" alt="" />
          <div class="gradient"></div>
        </div>
        <div class="text">
          <p>{{ $t('balkam-group.cycle.desc2') }}</p>
        </div>
      </main>

      <main class="main_box">
        <div class="image">
          <img src="@/assets/img/balkam-group/propr/3.jpg" alt="" />
          <div class="gradient"></div>
        </div>
        <div class="text">
          <p>{{ $t('balkam-group.cycle.desc3') }}</p>
        </div>
      </main>

      <main class="main_box">
        <div class="image">
          <img src="@/assets/img/balkam-group/propr/4.jpg" alt="" />
          <div class="gradient"></div>
        </div>
        <div class="text">
          <p>{{ $t('balkam-group.cycle.desc4') }}</p>
        </div>
      </main>
      <main class="main_box">
        <div class="image">
          <img src="@/assets/img/balkam-group/propr/5.jpg" alt="" />
          <div class="gradient"></div>
        </div>
        <div class="text">
          <p>
            {{ $t('balkam-group.cycle.desc5') }}
          </p>
        </div>
      </main>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    isVisible() {
      const wrapper = this.$refs.boxContainer.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window
      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.active = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.container {
  background: transparent linear-gradient(180deg, #e5edf0 0%, #eef2f4 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  display: flex;
  max-width: $defaultWidth;
  margin: 0 auto;
  padding: 20px;
  gap: 15px;
  scroll-snap-type: x mandatory;

  .main_box {
    width: 100%;
    position: relative;
    scroll-snap-align: start;

    .image {
      overflow: hidden;
      position: relative;

      img {
        border-radius: 20px;
        height: 279px;
        object-fit: cover;
        width: 100%;
      }

      .gradient {
        background: transparent linear-gradient(180deg, #00000000 0%, #00000000 65%, #000000 100%) 0% 0% no-repeat
          padding-box;
        bottom: 0;
        border-radius: 20px;
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
        z-index: 1;
      }
    }

    .text {
      bottom: 24px;
      color: white;
      text-align: center;
      position: absolute;
      left: 5%;
      width: 90%;
      z-index: 1;
      p {
        font: normal normal 600 17px/24px IBM Plex Sans;
      }
    }
  }
}
@media all and(max-width: $xsm) {
  .container {
    overflow-x: scroll;
    border-radius: 0px;
    padding: $sides;
    padding-left: 0;
    gap: 0;
    @include scrollbar(0, #fff);
    .main_box {
      padding-left: 0;
      .image {
        // width: 290px;
        width: 80vw;
        padding-left: $sides;
        .gradient {
          left: $sides;
          width: calc(100% - $sides);
        }
      }
      &.main_box__active {
        animation: 2s ease-in 0.5s alternate-reverse both slidein;
        @keyframes slidein {
          30% {
            margin-left: -120px;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0;
  }
}
</style>
