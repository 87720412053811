<template>
  <main class="container__brand">
    <div class="brand__header" to="/brands">
      <router-link to="/brandSantos" class="go__back">
        <img src="@/assets/img/brands/back.svg" alt="icon" />
      </router-link>
      <img class="brand__logo" src="@/assets/img/brands/lavista/lavista.svg" alt="" />
      <img class="brand__bcg" src="@/assets/img/brands/lavista/lavista.svg" alt="" />
      <router-link to="/brandNapoli" class="go__back__right">
        <img src="@/assets/img/brands/back.svg" alt="icon" />
      </router-link>
    </div>

    <div class="container__clm">
      <Carousell
        :images="[
          require('../../assets/img/brands/lavista/p1.png'),
          require('../../assets/img/brands/lavista/p2.png'),
          require('../../assets/img/brands/lavista/p3.png'),
          require('../../assets/img/brands/lavista/p4.png'),
          require('../../assets/img/brands/lavista/p5.png'),
        ]"
      />
      <div class="brand__text">
        <div class="text">
          <!-- <h1>{{ $t('brands.lavista.title') }}</h1> -->
          <p>{{ $t('brands.lavista.desc') }}</p>
          <p>{{ $t('brands.lavista.desc1') }}</p>
          <br />
          <p class="continets__text">{{ $t('brands.lavista.title2') }}</p>
        </div>

        <div class="brand__continents">
          <img src="@/assets/img/brands/lavista/map-lavista.svg" alt="" />
        </div>
      </div>
    </div>
    <Button @click="redirect('https://coffeemall.bg/')" class="btn" type="button">{{ $t('buttons.button4') }}</Button>
  </main>
</template>

<script>
import Button from '@/components/Button';
import Carousell from '@/components/Carousell';
export default {
  components: {
    Button,
    Carousell,
  },
  data() {
    return {};
  },
  methods: {
    redirect: function (link, target = '_blank') {
      window.open(link, target);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/brands.scss';
.container__brand {
  background: #57c6f0 0% 0% no-repeat padding-box;
}
</style>