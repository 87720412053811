<template>
  <main class="about-container">
    <section ref="about" class="about">
      <div class="texts">
        <h2>{{ $t('balkam-group.title1') }}</h2>
        <p>{{ $t('balkam-group.desc') }}</p>
        <p>{{ $t('balkam-group.desc1') }}</p>
      </div>

      <div class="slider">
        <transition name="slide-left">
          <template v-for="(slide, i) in slides">
            <div
              class="slide"
              :key="i"
              :style="{
                backgroundImage: `url(${slide.img})`,
              }"
              v-if="current === i"
            >
              <div class="gradient"></div>
              <p>{{ slide.title }}</p>
            </div>
          </template>
        </transition>
      </div>
    </section>

    <div class="values" ref="cycle">
      <div class="texts">
        <h2 class="center">{{ $t('balkam-group.title2') }}</h2>
      </div>
      <img src="../../assets/img/balkam-group/values.svg" alt="" />
    </div>

    <div class="texts" ref="technology">
      <h2 class="center">{{ $t('balkam-group.title3') }}</h2>
      <p class="center">
        {{ $t('balkam-group.paragraph4') }}
      </p>
      <p class="center">
        {{ $t('balkam-group.paragraph6') }}
      </p>
    </div>

    <Cycle />

    <div class="texts" ref="sustainability">
      <h2 class="center">{{ $t('balkam-group.title4') }}</h2>
      <p class="center">
        {{ $t('balkam-group.paragraph5') }}
      </p>
    </div>
    <div class="texts">
      <h2 class="center">{{ $t('balkam-group.title5') }}</h2>
    </div>

    <Sustainability />

    <div class="texts certificates" ref="certificates">
      <h2 class="center">{{ $t('balkam-group.certificates.title') }}</h2>
      <p class="center">
        {{ $t('balkam-group.certificates.text') }}
      </p>
      <img src="@/assets/img/balkam-group/sertificati.svg" alt="certificates" />
    </div>
  </main>
</template>

<script>
import Cycle from './Cycle';
import Sustainability from './Sustainability.vue';

export default {
  data() {
    return {
      active: false,

      slides: [
        { img: require('../../assets/img/balkam-group/weare/1.jpg'), title: this.$t('balkam-group.paragraph') },
        { img: require('../../assets/img/balkam-group/weare/2.jpg'), title: this.$t('balkam-group.paragraph1') },
        { img: require('../../assets/img/balkam-group/weare/3.png'), title: this.$t('balkam-group.paragraph2') },
        { img: require('../../assets/img/balkam-group/weare/4.png'), title: this.$t('balkam-group.paragraph3') },
      ],
      current: 0,
    };
  },
  methods: {
    nextSlide() {
      this.current++;
      if (this.current >= this.slides.length) this.current = 0;
      this.resetPlay();
    },
    resetPlay() {
      clearInterval(this.timer);
      this.play();
    },
    play() {
      let app = this;
      this.timer = setInterval(function () {
        app.nextSlide();
      }, 6500);
    },

    scrollTo() {
      if (this.$route.query.scr) {
        const element = this.$refs[this.$route.query.scr];
        this.$nextTick(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        });
      }
    },
  },
  created: function () {
    this.play();
  },
  watch: {
    $route: { handler: 'scrollTo' },
  },
  mounted() {
    this.scrollTo();
  },
  components: {
    Cycle,
    Sustainability,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.about-container {
  color: #444444;
  margin: 0 auto;
  max-width: $maxWidthWithSides;
  padding: 0 $sides;

  .about {
    padding: 20px 0;
    display: flex;
    margin: 0 auto 30px;

    align-items: stretch;
    gap: 20px;

    div {
      width: 50%;
    }
    .texts {
      padding: 0;
    }
    .text {
      align-self: center;
      h1 {
        padding-bottom: 20px;
      }
      :nth-child(2) {
        padding-bottom: 20px;
      }
    }
  }
  .slider {
    border-radius: 20px;
    margin: 0 auto;
    overflow: hidden;
    //height: 350px;
    padding: 0;
    position: relative;
    width: 750px;

    .slide {
      align-items: flex-end;
      background-position: center;
      background-size: cover;
      border-radius: 20px;
      color: white;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      text-align: center;
      position: absolute;
      top: 0;
      width: 100%;

      .gradient {
        background: transparent linear-gradient(180deg, #00000000 0%, #00000000 65%, #000000 100%) 0% 0% no-repeat
          padding-box;
        border-radius: 20px;
        height: 100%;
        width: 100%;
      }
      p {
        position: absolute;
        padding: 0 20px 20px 20px;
      }
    }
  }

  // .about {
  //   display: flex;
  //
  //   margin: 0 auto;
  //   align-items: stretch;
  //   gap: 15px;

  //   div {
  //     width: 50%;
  //   }
  // }

  > .texts {
    padding: 20px 0;

    h2:last-child {
      margin: 0;
    }
  }

  .values {
    align-items: center;
    background: transparent linear-gradient(180deg, #e5edf0 0%, #eef2f4 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    .texts {
      padding: 0;
    }
    h2 {
      margin: 34px 0;
    }
    img {
      width: 90%;
      padding-bottom: 70px;
    }
  }
  .certificates {
    padding: 0 40px;
    margin: 0 auto;
    text-align: center;

    h1 {
      padding: 20px 0 20px 0;
      font-size: 36px;
    }
    p {
      padding-bottom: 20px;
    }
    img {
      height: 100px;
      &.mobile {
        display: none;
      }
    }
    // . {
    //   background-size: contain;
    //   height: 100px;
    //   background-position: center;
    //   background-repeat: no-repeat;
    // }
  }
}
@media all and (max-width: $xsm) {
  .about-container {
    padding: 0;

    .about {
      flex-direction: column;
      margin-bottom: 0;

      .texts {
        padding: 0 $sides;
        text-align: center;
        width: 100%;
      }

      .slider {
        width: calc(100% - 2 * $sides);
        height: 350px;
      }
    }

    .values {
      img {
        width: 80%;
      }
    }
    div {
      border-radius: 0px;
    }
    .certificates {
      width: 100%;
      h1 {
        font-size: 30px;
      }
      img {
        width: 100%;
      }
    }
    ::-webkit-scrollbar {
      width: 0;
    }
    > .texts {
      padding: 20px $sides;
    }
  }
}
</style>
