<template>
  <div class="pageNotFound">
    <img src="@/assets/img/404.svg" alt="" />
    <h2>{{ $t('pageNotFound.title') }}</h2>
    <h1>{{ $t('pageNotFound.title2') }}</h1>
    <router-link to="/">
      <Button class="btn" type="button">{{ $t('pageNotFound.toHome') }}</Button>
    </router-link>
  </div>
</template>

<script>
import Button from '@/components/Button';
export default {
  components: {
    Button,
  },
  watch: {
    $route: { handler: 'scrollTo' },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.pageNotFound {
  color: #513131;
  flex-direction: column;
  text-align: center;
  margin: 40px auto;
  max-width: $maxWidth;
  img{
    padding: 0 20px;
    margin: auto;
    max-width: 830px;
    width: 100%;
  }
  h2{
    padding-top: 20px;
    font: normal normal bold 48px/62px IBM Plex Sans;
  }
  h1{
    font: normal normal normal 28px/37px IBM Plex Sans;
  }
  .btn{
    margin-top: 20px;
  }
}
@media all and(max-width:$s){
.pageNotFound {
  h2{
    font: normal normal bold 28px/32px IBM Plex Sans;
  }
  h1{
    font: normal normal normal 18px/27px IBM Plex Sans;
  }
}
}
</style>
