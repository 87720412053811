<template>
  <div class="div__carousell">
    <transition name="slide-left">
      <template v-for="(i, index) in images">
        <div v-if="active === index" :key="index">
          <img :src="i" />
        </div>
      </template>
    </transition>
  </div>
</template>
<script>
export default {
  props: ['images'],
  data() {
    return {
      active: 0,
      interval: null,
    };
  },
  methods: {
    startRotation() {
      this.interval = setInterval(() => {
        if (this.images.length - 1 > this.active) {
          this.active += 1;
        } else this.active = 0;
      }, 2000);
    },
  },
  mounted() {
    this.startRotation();
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';

.div__carousell {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 50%;

  &::before {
    bottom: 20%;
    /* background-color: rgba($color: #fff, $alpha: 0.2); */
    border-radius: 20px;
    content: '';
    height: 60%;
    left: 0;
    position: absolute;
    width: 100%;
  }

  div {
    align-self: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    width: 100%;

    img {
      max-height: 330px;
      max-width: 350px;
      object-fit: scale-down;
      z-index: 3;
    }
    @keyframes slideToLeft {
      0%,
      10% {
        transform: translateX(0);
      }
      16% {
        transform: translateX(-100%);
      }
      33% {
        transform: translateX(-200%);
      }
      49% {
        transform: translateX(-300%);
      }
      66% {
        transform: translateX(-400%);
      }
      83% {
        transform: translateX(-500%);
      }
      100% {
        transform: translateX(-600%);
      }
      100%,
      0% {
        transform: translateX(0);
      }
    }
  }
}

@media (min-width: 1900px) {
  .div__carousell {
    // width: 1800px;
    padding: 0 40px;
  }
}

@media (max-width: $xsm) {
  .div__carousell {
    display: flex;
    overflow-x: hidden;
    width: 100%;
    height: 300px;
    &::before {
      left: $sides;
      width: calc(100% - (#{$sides} * 2));
    }

    div {
      margin: 0 auto;
      width: 80%;

      img {
        height: 300px;
        width: 300px;
      }
    }
  }

  @media (max-width: 420px) {
    .div__carousell {
      display: flex;
      overflow-x: hidden;
      width: 100%;
      height: 300px;

      div {
        margin: 0 auto;
        width: 80%;

        img {
          height: 250px;
          width: 250px;
        }
      }
    }
  }
}
</style>


