<template>
  <div class="container__end">
    <main>
      <div class="logo__lng">
        <div class="lng">
          <img class="logo" src="@/assets/img/logo-v.svg" alt="Balkam" />
          <span @click="changeLocale">
            <span class="lng-en" v-if="this.$i18n.locale == 'en'">English</span>
            <span class="lng-bg" v-else>Български</span>
          </span>
        </div>

        <span class="dsc">
          {{ $t('footer.end.credits') }} <br />
          <a href="https://cdots.bg"> {{ $t('footer.end.cdots') }}</a>
        </span>
      </div>

      <div class="info">
        <div class="box">
          <p class="title">
            <a href="/balkam-group"> {{ $t('footer.end.firstTitle') }}</a>
          </p>
          <hr />
          <span
            ><a href="/balkam-group?scr=1">{{ $t('footer.end.aboutUs') }}</a></span
          >
          <span
            ><a href="/brands"> {{ $t('footer.end.brands') }}</a></span
          >
          <span
            ><a href="/blends">{{ $t('footer.end.blends') }}</a></span
          >
        </div>

        <div class="box">
          <p class="title">
            <a href="/products"> {{ $t('footer.end.secondTitle') }}</a>
          </p>
          <hr />
          <span
            ><a href="/products">{{ $t('footer.end.capsules') }}</a></span
          >
          <span
            ><a href="/beans-and-grinded">{{ $t('footer.end.cofffe') }}</a></span
          >
          <span
            ><a href="/soluble-drinks">{{ $t('footer.end.drinks') }}</a></span
          >
        </div>

        <div class="box">
          <p class="title">{{ $t('footer.end.links') }}</p>
          <hr />
          <span>
            <a href="https://spetema.com/" target="_blank">www.spetema.com</a>
          </span>
          <span>
            <a href="https://balkam-group.com/" target="_blank">www.balkam-group.com</a>
          </span>
          <span>
            <a href="https://coffeemall.bg/" target="_blank">www.coffeemall.bg</a>
          </span>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  methods: {
    changeLocale() {
      this.$i18n.locale = this.$i18n.locale === 'en' ? 'bg' : 'en';
      localStorage.setItem('locale', this.$i18n.locale);
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.container__end {
  background: #1a1a1a 0% 0% no-repeat padding-box;
  color: #dddddd;
  overflow: hidden;
  main {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: $maxWidthWithSides;
    padding: $sides;
    .logo__lng {
      display: flex;
      flex-direction: column;
      padding-right: 195px;

      .lng {
        flex-basis: 20%;
        position: relative;
        .lng-bg,
        .lng-en {
          cursor: pointer;
          font-family: 'IBM Plex Serif', Helvetica, Arial, sans-serif;
          position: absolute;
          top: 20px;
        }
        .logo {
          border-right: 2px solid #99999980;
          margin-right: 10px;
          padding-right: 7px;
          width: 40%;
        }
      }
      .dsc {
        padding-top: 20px;
      }
      p {
        padding-top: 10px;
        font-family: 'IBM Plex Serif', Helvetica, Arial, sans-serif;
      }
    }

    .info {
      display: flex;
      flex-wrap: wrap;

      .box {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        min-width: 180px;
        width: 12.5vw;

        &:not(:first-child) {
          margin-left: $sides;
        }
        .title {
          padding-bottom: 0.625em;
          font-family: 'IBM Plex Serif', Helvetica, Arial, sans-serif;
          border-bottom: 1px solid #99999980;
          margin-bottom: 1.25em;
        }

        span:first-of-type {
          margin-top: 3.375em;
        }
        span {
          padding-bottom: 1.25em;
          a {
            @include hover();
          }
        }
        hr {
          display: block;
          height: 1px;
          width: 1.25em;
          border-top: 1px solid #ccc;
          margin: -1.4em 0;
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .container__end {
    main {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .logo__lng {
        padding-right: 0;
        .lng {
          padding-bottom: 0;
          margin-left: 4.125em;
        }
      }
      .dsc {
        padding: 0;
      }
      .info {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        padding-top: 1.25em;
        .box {
          padding-right: 0;
          padding-bottom: 2.5em;
        }
      }
    }
  }
}

@media only screen and (max-width: $s) {
  .container__end {
    overflow-x: hidden;
    main {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      .logo__lng {
        padding-right: 0;
        .lng {
          padding-bottom: 1.875em;
        }
      }
      .dsc {
        text-align: center;
        padding: 20px 0;
      }
      .info {
        display: flex;
        flex-direction: column;
        text-align: center;
        // width: 100%;
        .box {
          margin: 0;
          width: 100%;

          &:not(:first-child) {
            margin: 0;
          }
          .title {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>