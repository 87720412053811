<template>
  <main class="sustainability-container">
    <section class="sustainability-container-section">
      <main class="sustainability-container-main" ref="whyBalkamBox">
        <div class="sustainability__box__element" :class="{ sustainability__box__element__active: active }">
          <img src="@/assets/img/balkam-group/sust/1.jpg" alt="" />
          <div class="gradient"></div>
          <div class="text">
            <p>
              {{ $t('balkam-group.sustainability.firstcart.title') }}
            </p>
            <span class="box-subtitle">{{ $t('balkam-group.sustainability.firstcart.line1') }}</span>
            <span class="box-subtitle">{{ $t('balkam-group.sustainability.firstcart.line2') }}</span>
            <span class="box-subtitle">{{ $t('balkam-group.sustainability.firstcart.line3') }}</span>
            <span class="box-subtitle">{{ $t('balkam-group.sustainability.firstcart.line4') }}</span>
          </div>
        </div>

        <div class="sustainability__box__element">
          <img src="@/assets/img/balkam-group/sust/2.jpg" alt="" />
          <div class="gradient"></div>
          <div class="text">
            <p>
              {{ $t('balkam-group.sustainability.secondcart.title') }}
            </p>
            <span class="box-subtitle">{{ $t('balkam-group.sustainability.secondcart.line1') }}</span>
            <span class="box-subtitle">{{ $t('balkam-group.sustainability.secondcart.line2') }}</span>
          </div>
        </div>

        <div class="sustainability__box__element">
          <img src="@/assets/img/balkam-group/sust/3.jpg" alt="" />
          <div class="gradient"></div>
          <div class="text">
            <p>
              {{ $t('balkam-group.sustainability.thirdcart.title') }}
            </p>
            <span class="box-subtitle">{{ $t('balkam-group.sustainability.thirdcart.line1') }}</span>
            <span class="box-subtitle">{{ $t('balkam-group.sustainability.thirdcart.line2') }}</span>
            <span class="box-subtitle">{{ $t('balkam-group.sustainability.thirdcart.line3') }}</span>
            <span class="box-subtitle">{{ $t('balkam-group.sustainability.thirdcart.line4') }}</span>
          </div>
        </div>
      </main>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    isVisible() {
      const wrapper = this.$refs.whyBalkamBox.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window

      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.active = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.sustainability-container {
  max-width: $defaultWidth;
  text-align: center;
  margin: 0 auto;

  .sustainability-container-section {
    margin: 0 auto;
    max-width: $defaultWidth;
    position: relative;

    .sustainability-container-main {
      background: transparent linear-gradient(180deg, #e5edf0 0%, #eef2f4 100%) 0% 0% no-repeat padding-box;
      border-radius: 20px;
      padding: 30px;
      gap: 30px;
      display: flex;

      .sustainability__box__element {
        height: 300px;
        position: relative;
        width: 33%;

        img {
          border-radius: 20px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .gradient {
          border-radius: 20px;
          background: transparent linear-gradient(180deg, #00000000 0%, #00000000 65%, #000000 100%) 0% 0% no-repeat
            padding-box;
          bottom: 0;
          height: 100%;
          left: 0;
          border-radius: 20px;
          position: absolute;
          width: 100%;
        }
      }
      .text {
        bottom: 12px;
        color: white;
        position: absolute;
        left: 5%;
        text-align: center;
        width: 90%;
        z-index: 1;
        p {
          font-weight: 600;
          font-size: 26px;
        }
        span {
          font-size: 15px;
        }
      }
    }
  }
}
@media (max-width: $xsm) {
  .sustainability-container {
    .sustainability-container-section {
      overflow-x: hidden;

      .sustainability-container-main {
        border-radius: 0;
        margin: 0vh;
        scroll-snap-type: x mandatory;
        padding: $sides;
        gap: 0;
        overflow-x: scroll;
        @include scrollbar(0, #fff);
        width: 100%;
        .sustainability__box__element {
          height: 300px;
          scroll-snap-align: start;
          margin-left: $sides;
          padding-left: $sides;
          margin-left: 0;
          min-width: 85vw;
          width: 85vw;

          .gradient {
            left: $sides;
            width: calc(85vw - $sides);
          }

          &.sustainability__box__element__active {
            animation: 2s ease-in 0.5s alternate-reverse both slidein;

            @keyframes slidein {
              30% {
                margin-left: -120px;
              }
            }
          }
        }
      }
    }
    ::-webkit-scrollbar {
      width: 0;
    }
  }
  h1 {
    font-size: 30px;
    line-height: 34px;
  }
}
@media all and(max-width:500px) {
  .sustainability-container {
    .sustainability-container-section {
      .sustainability-container-main {
        .sustainability__box__element {
          .gradient {
            background: transparent linear-gradient(#00000000 0%, #00000000 10%, #000000 100%) 0% 0% no-repeat;
          }
          .text {
            top: 55%;
            p {
              font-size: 25px;
            }
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
