<template>
  <footer class="ftr">
    <TheFooterNumbers />
    <!-- <TheFooterInquiry /> -->
    <TheFooterAddress />
    <TheFooterEnd />
  </footer>
</template>

<script>
import TheFooterNumbers from './TheFooterNumbers';
import TheFooterAddress from './TheFooterAddress';
import TheFooterEnd from './TheFooterEnd';
export default {
  components: {
    TheFooterNumbers,
    TheFooterAddress,
    TheFooterEnd,
  },
};
</script>

<style>
</style>