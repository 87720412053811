<template>
  <main class="brands">
    <section class="brands__container">
      <Product
        :imageSource="require('../../assets/img/brands/spetema/spetema-small.svg')"
        :imageSourceProduct="require('../../assets/img/brands/spetema/1.png')"
        :title="$t('brands.spetema.title')"
        :link="'/brandSpetema'"
        class="product"
      />

      <Product
        :imageSource="require('../../assets/img/brands/balkam/balkam-small.svg')"
        :imageSourceProduct="require('../../assets/img/brands/balkam/1.png')"
        :title="$t('brands.balkam.title')"
        :link="'/brandBalkam'"
        class="product"
      />

      <Product
        :imageSource="require('../../assets/img/brands/santos/santos-small.svg')"
        :imageSourceProduct="require('../../assets/img/brands/santos/1.png')"
        :title="$t('brands.santos.title')"
        class="product"
        :link="'/brandSantos'"
      />

      <Product
        :imageSource="require('../../assets/img/brands/lavista/lavista-small.svg')"
        :imageSourceProduct="require('../../assets/img/brands/lavista/1.png')"
        :title="$t('brands.lavista.title')"
        :link="'/brandLavista'"
        class="product"
      />

      <Product
        :imageSource="require('../../assets/img/brands/napoli/napoli-small.svg')"
        :imageSourceProduct="require('../../assets/img/brands/napoli/1.png')"
        :title="$t('brands.napoli.title')"
        class="product"
        :link="'/brandNapoli'"
      />

      <Product
        :imageSource="require('../../assets/img/brands/verdico/verdico-small.svg')"
        :imageSourceProduct="require('../../assets/img/brands/verdico/1.png')"
        :title="$t('brands.verdico.title')"
        :link="'/BrandVerdico'"
        class="product"
      />
    </section>

    <div class="texts">
      <h2 class="center">{{ $t('brands.titles2') }}</h2>
    </div>

    <section class="brands__container">
      <Product
        :imageSource="require('../../assets/img/brands/caprimo/caprimo.png')"
        :imageSourceProduct="require('../../assets/img/brands/caprimo/1.png')"
        :title="$t('brands.verdico.title')"
        :link="'/BrandCaprimo'"
        class="product"
      />
            <Product
        :imageSource="require('../../assets/img/brands/vanHouten/vanHouten.png')"
        :imageSourceProduct="require('../../assets/img/brands/vanHouten/1.png')"
        :title="$t('brands.verdico.title')"
        :link="'/BrandVanHouten'"
        class="product"
      />
            <Product
        :imageSource="require('../../assets/img/brands/maison/maison-small.svg')"
        :imageSourceProduct="require('../../assets/img/brands/maison/1.png')"
        :title="$t('brands.verdico.title')"
        :link="'/BrandMaison'"
        class="product"
      />
            <Product
        :imageSource="require('../../assets/img/brands/te/te-small.svg')"
        :imageSourceProduct="require('../../assets/img/brands/te/1.png')"
        :title="$t('brands.verdico.title')"
        :link="'/BrandTe'"
        class="product"
      />
            <Product
        :imageSource="require('../../assets/img/brands/basilur/basilur-small.svg')"
        :imageSourceProduct="require('../../assets/img/brands/basilur/1.png')"
        :title="$t('brands.verdico.title')"
        :link="'/BrandBasilur'"
        class="product"
      />
            <Product
        :imageSource="require('../../assets/img/brands/greenIsland/greenIsland-small.svg')"
        :imageSourceProduct="require('../../assets/img/brands/greenIsland/1.png')"
        :title="$t('brands.verdico.title')"
        :link="'/BrandGreenIsland'"
        class="product"
        :right="true"
      />
    </section>
  </main>
</template>

<script>
import Product from './Product';
export default {
  components: {
    Product,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
//added class to products
.brands {
  max-width: $maxWidth;
  margin: 20px auto;
  
  .texts {
    h2 {
      margin: 34px 0;
    }

    @media screen and (max-width: $xsm) {
      & {
        text-align: center;
      }
    }
  }
}
.brands__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .product {
    width: 31%;
    margin: 10px;
  }
}
@media all and(max-width: $xsm) {
  .brands__container {
    .product {
      width: 90%;
      margin: 10px;
    }
  }
}
</style>
