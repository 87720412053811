<template>
  <div class="contacts">
    <header class="main-header" :style="{ backgroundImage: `url(${require('@/assets/img/contacts/header.png')})` }">
      <h1>{{ $t('contacts.title') }}</h1>
    </header>
    <Contacts />
  </div>
</template>

<script>
import Contacts from './Contacts'

export default {
  components: {
    Contacts
  }
};
</script>

<style>
</style>