<template>
  <div class="home__video">
    <iframe
      height="100%"
      width="100%"
      :src="source"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
      <!-- src="https://www.youtube.com/embed/kT0RAZA8k7M?controls=0&autoplay=1&playlist=BL8s1vyEvf8&loop=1&mute=1" -->

    <div class="home__video__content">
      <p>{{ subtitle }}</p>
      <h2>{{ title }}</h2>
      <h2>{{ title2 }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: ['subtitle', 'title', 'title2','source'],
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.home__video {
  margin-top: -60px;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .home__video__content {
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    flex-direction: column;

    > h2 {
      max-width: 1140px;
      margin: 0 auto;
      text-align: center;
      font: normal normal bold 50px/72px IBM Plex Sans;
      color: white;
  
    }
    > p {
      max-width: 1140px;
      margin: 0 auto;
      text-align: center;
      font: normal normal normal 28px/72px IBM Plex Serif;
      color: white;
      padding: 20 px 0;
      
    }
  }
}

@media (max-width: $xsm) {
  .home__video {
    padding-top: 178.25%;
    height: auto;

    .home__video__content {

      h2 {
        font: normal normal bold 35px/72px IBM Plex Sans;
        line-height: 1.2;
      }

      p {
        line-height: 1.2;
        padding: 30px 0;
      }
    }
  }
}
</style>