<template>
  <div id="app">
    <TheHeader />
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
    <vue-snotify></vue-snotify>
    <TheFooter
      v-if="
        $route.name !== 'BrandSpetema' &&
        $route.name !== 'BrandBalkam' &&
        $route.name !== 'BrandSantos' &&
        $route.name !== 'BrandNapoli' &&
        $route.name !== 'BrandLavista' &&
        $route.name !== 'BrandVerdico' &&
        $route.name !== 'BrandCaprimo' &&
        $route.name !== 'BrandVanHouten' &&
        $route.name !== 'BrandMaison' &&
        $route.name !== 'BrandTe' &&
        $route.name !== 'BrandBasilur' &&
        $route.name !== 'BrandGreenIsland'
      "
    />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader';
import TheFooter from '@/components/TheFooter';
export default {
  components: {
    TheHeader,
    TheFooter,
  },
  watch: {
    $route() {
      window.scroll(0, 0);
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/reset.scss';
@import '@/scss/base.scss';
@import '@/scss/texts.scss';
@import '@/scss/main.scss';
@import '~vue-snotify/styles/material';
#app {
  font-family: 'IBM Plex Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBM Plex Serif', Helvetica, Arial, sans-serif;
  font-weight: 500;
}

h2 {
  font-size: 36px;
  line-height: 40px;
}

input,
textarea {
  font-family: 'IBM Plex Sans', Helvetica, Arial, sans-serif;
}

.slide-enter-active {
  animation: slide-in 300ms ease-out forwards;
}

.slide-leave-active {
  animation: slide-out 300ms ease-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-30px);
    opacity: 0;
  }
}
</style>
