<template>
  <button v-on="$listeners" >
    <span>
      <slot />
    </span>
    <div class="button__hover">
      <slot />
    </div>
  </button>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
button {
  background-color: #18191b;
  border-radius: 2px;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  height: 37px;
  min-width: 160px;
  padding: 0 36px;
  position: relative;

  > span {
    position: relative;
    z-index: 1;
  }

  .button__hover {
    align-items: center;
    background-color: inherit;
    border: 2px solid #000;
    border-radius: inherit;
    color: transparent;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    letter-spacing: 1.44px;
    position: absolute;
    top: 0;
    width: 100%;
    transition: border 0.3s $cubic, left 0.3s $cubic, width 0.3s $cubic;
  }
  &:hover {
    background-color: transparent;
    color: transparent;
    .button__hover {
      border: 2px solid #000;
      color: #000;
      left: -10px;
      width: calc(100% + 20px);
    }
  }
}
</style>