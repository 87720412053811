<template>
  <div class="marketing__container">
    <div class="texts">
      <h2 class="center">{{ $t('marketing.findproducts') }}</h2>
      <p class="center">{{ $t('marketing.findproducts-desc') }}</p>
    </div>

    <div class="representations">
      <section @click="tr = false" :class="{ representations__active: !tr }">
        <img v-if="!tr" src="../../assets/img/marketing/map-bg-small.svg" alt="" />
        <img v-else src="../../assets/img/marketing/map-bg-small-black.svg" alt="" />
        <h1>{{ $t('marketing.representations') }}</h1>
      </section>

      <section @click="tr = true" :class="{ representations__active: tr }">
        <h1>{{ $t('marketing.representations-tr') }}</h1>
        <img v-if="tr" src="../../assets/img/marketing/map-tr-small-orange.svg" alt="" />
        <img v-else src="../../assets/img/marketing/map-tr-small.svg" alt="" />
      </section>
    </div>

    <div v-if="!tr" class="map">
      <img class="map__img" src="../../assets/img/marketing/map-bg.svg" alt="" />

      <div
        class="city"
        v-for="c in cities"
        :class="[c.class, c.isSelected ? 'selected ' : '']"
        :key="c.name"
        @click="select(c.name, c.isSelected)"
      >
        <header>
          <div>
            <img src="../../assets/img/marketing/logo.svg" alt="" />
            <!-- <p>{{ $t('marketing.manager') }}</p> -->
          </div>
          <img class="exit" src="../../assets/img/marketing/exit.svg" alt="" />
        </header>
        <div class="dash"></div>
        <section>
          <h1>{{ c.name }}</h1>
          <div>
            <img class="exit" src="../../assets/img/marketing/address.svg" alt="" />
            <a :href="`https://goo.gl/maps/${c.location}`" target="_blank">{{ c.address }}</a>
          </div>
          <div>
            <img class="exit" src="../../assets/img/marketing/email.svg" alt="" />
            <a :href="`mailto:${c.email}`">{{ c.email }}</a>
          </div>
          <div>
            <img class="exit" src="../../assets/img/marketing/phone.svg" alt="" />
            <a :href="`tel:${c.phone}`">{{ $t('marketing.manager') }} {{ c.phone }}</a>
          </div>
          <div>
            <img class="exit" src="../../assets/img/marketing/phone.svg" alt="" />
            <a :href="`tel:${c.phone2}`">{{ $t('marketing.manager2') }} {{ c.phone2 }}</a>
          </div>
        </section>
      </div>
    </div>
    <div class="map" v-else>
      <img class="map__img" src="../../assets/img/marketing/map-tr.svg" alt="" />

      <div
        class="city"
        v-for="c in citiesTr"
        :key="c.name"
        :class="[c.class, c.isSelected ? 'selected ' : '']"
        @click="selectTR(c.name, c.isSelected)"
      >
        <header>
          <div>
            <img src="../../assets/img/marketing/logo.svg" alt="" />
            <!-- <p>{{ $t('marketing.manager') }}</p> -->
          </div>
          <img class="exit" src="../../assets/img/marketing/exit.svg" alt="" />
        </header>
        <div class="dash"></div>
        <section>
          <h1>{{ c.name }}</h1>
          <div>
            <img class="exit" src="../../assets/img/marketing/address.svg" alt="" />
            <a :href="`https://goo.gl/maps/${c.location}`">{{ c.address }}</a>
          </div>
          <div>
            <img class="exit" src="../../assets/img/marketing/email.svg" alt="" />
            <a :href="`mailto:${c.email}`">{{ c.email }}</a>
          </div>
          <div>
            <img class="exit" src="../../assets/img/marketing/phone.svg" alt="" />
            <a :href="`tel:${c.phone}`">{{ $t('marketing.manager') }}{{ c.phone }}</a>
          </div>
          <div>
            <img class="exit" src="../../assets/img/marketing/phone.svg" alt="" />
            <a :href="`tel:${c.phone2}`">{{ $t('marketing.manager2') }} {{ c.phone2 }}</a>
          </div>
        </section>
      </div>
    </div>
    <div class="texts">
      <h2 class="center">{{ $t('marketing.channels') }}</h2>
      <p class="center">{{ $t('marketing.desc') }}</p>
      <p class="center">{{ $t('marketing.desc1') }}</p>
      <p class="center">{{ $t('marketing.desc2') }}</p>
    </div>

    <div class="div__box" ref="boxContainer">
      <section>
        <div
          class="div__box__el position"
          :class="{ div__box__el__active: active }"
          :style="{
            backgroundImage: `url(${require('../../assets/img/marketing/d1.jpg')})`,
          }"
        >
          <div class="gradient"></div>
          <div class="text">
            <p>{{ $t('marketing.channels1') }}</p>
          </div>
        </div>
        <span>{{ $t('marketing.span') }}</span>
      </section>

      <section>
        <div
          class="div__box__el"
          :class="{ div__box__el__active: active }"
          :style="{
            backgroundImage: `url(${require('../../assets/img/marketing/d2.jpg')})`,
          }"
        >
          <div class="gradient"></div>
          <div class="text fix">
            <p>{{ $t('marketing.channels2') }}</p>
          </div>
        </div>
        <span>{{ $t('marketing.span2') }}</span>
      </section>

      <section>
        <div
          class="div__box__el"
          :class="{ div__box__el__active: active }"
          :style="{
            backgroundImage: `url(${require('../../assets/img/marketing/d3.jpg')})`,
          }"
        >
          <div class="gradient"></div>
          <div class="text">
            <p>{{ $t('marketing.channels3') }}</p>
          </div>
        </div>
        <span>{{ $t('marketing.span3') }}</span>
      </section>

      <section>
        <div
          class="div__box__el position"
          :class="{ div__box__el__active: active }"
          :style="{
            backgroundImage: `url(${require('../../assets/img/marketing/d4.jpg')})`,
          }"
        >
          <div class="gradient"></div>
          <div class="text">
            <p>{{ $t('marketing.channels4') }}</p>
          </div>
        </div>
        <span>{{ $t('marketing.span4') }}</span>
      </section>
    </div>

    <div class="texts">
      <h2 class="center">{{ $t('marketing.tech') }}</h2>
      <p class="center">{{ $t('marketing.tech1') }}</p>
      <p class="center">{{ $t('marketing.tech2') }}</p>
        <p class="center">
          <img src="@/assets/img/marketing/tbaza.png" alt="certificates" />
        </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
      tr: false,
      cityModal: false,

      cities: [
        {
          name: this.$t('marketing.sofia.title'),
          address: this.$t('marketing.sofia.address'),
          email: this.$t('marketing.sofia.email'),
          phone: this.$t('marketing.sofia.phone'),
          phone2: this.$t('marketing.sofia.phone2'),
          class: 'sofia',
          location: 'SS7t4oy5iJozjJzA6',
          isSelected: false,
        },
        {
          name: this.$t('marketing.sofia-bz.title'),
          address: this.$t('marketing.sofia-bz.address'),
          email: this.$t('marketing.sofia-bz.email'),
          phone: this.$t('marketing.sofia-bz.phone'),
          phone2: this.$t('marketing.sofia-bz.phone2'),
          class: 'sofia-bz',
          location: 'UvmR3zyWJxEcfPUq5',
          isSelected: false,
        },
        {
          name: this.$t('marketing.blagoevgrad.title'),
          address: this.$t('marketing.blagoevgrad.address'),
          email: this.$t('marketing.blagoevgrad.email'),
          phone: this.$t('marketing.blagoevgrad.phone'),
          phone2: this.$t('marketing.blagoevgrad.phone2'),
          class: 'blagoevgrad',
          location: 'EPyjBCGwxcFfiFk26',
          isSelected: false,
        },
        {
          name: this.$t('marketing.burgas.title'),
          address: this.$t('marketing.burgas.address'),
          email: this.$t('marketing.burgas.email'),
          phone: this.$t('marketing.burgas.phone'),
          phone2: this.$t('marketing.burgas.phone2'),
          class: 'burgas',
          location: '7vLAQscr7Bn3BxSHA',
          isSelected: false,
        },
        {
          name: this.$t('marketing.varna.title'),
          address: this.$t('marketing.varna.address'),
          email: this.$t('marketing.varna.email'),
          phone: this.$t('marketing.varna.phone'),
          phone2: this.$t('marketing.varna.phone2'),
          class: 'varna',
          location: '6zg2H5SqeisNmUbm6',
          isSelected: false,
        },
        {
          name: this.$t('marketing.velikoTurnovo.title'),
          address: this.$t('marketing.velikoTurnovo.address'),
          email: this.$t('marketing.velikoTurnovo.email'),
          phone: this.$t('marketing.velikoTurnovo.phone'),
          phone2: this.$t('marketing.velikoTurnovo.phone2'),
          class: 'velikoTurnovo',
          location: '5GhouaRWzCaRdFDN6',
          isSelected: false,
        },
        {
          name: this.$t('marketing.montana.title'),
          address: this.$t('marketing.montana.address'),
          email: this.$t('marketing.montana.email'),
          phone: this.$t('marketing.montana.phone'),
          phone2: this.$t('marketing.montana.phone2'),
          class: 'montana',
          location: 'sFjUfnp3WSsWX9qp7',
          isSelected: false,
        },
        {
          name: this.$t('marketing.pleven.title'),
          address: this.$t('marketing.pleven.address'),
          email: this.$t('marketing.pleven.email'),
          phone: this.$t('marketing.pleven.phone'),
          phone2: this.$t('marketing.pleven.phone2'),
          class: 'pleven',
          location: 'zfGkW2RJMkLKNQ6b9',
          isSelected: false,
        },
        {
          name: this.$t('marketing.plovdiv.title'),
          address: this.$t('marketing.plovdiv.address'),
          email: this.$t('marketing.plovdiv.email'),
          phone: this.$t('marketing.plovdiv.phone'),
          phone2: this.$t('marketing.plovdiv.phone2'),
          class: 'plovdiv',
          location: 'ZjjirsxiMYCYEL1V6',
          isSelected: false,
        },
        {
          name: this.$t('marketing.ruse.title'),
          address: this.$t('marketing.ruse.address'),
          email: this.$t('marketing.ruse.email'),
          phone: this.$t('marketing.ruse.phone'),
          phone2: this.$t('marketing.ruse.phone2'),
          class: 'ruse',
          location: '3qGUCDHBYEDDjVaB7',
          isSelected: false,
        },
        {
          name: this.$t('marketing.sandanski.title'),
          address: this.$t('marketing.sandanski.address'),
          email: this.$t('marketing.sandanski.email'),
          phone: this.$t('marketing.sandanski.phone'),
          phone2: this.$t('marketing.sandanski.phone2'),
          class: 'sandanski',
          location: 'TqUG1En9NRggDgJx9',
          isSelected: false,
        },
        {
          name: this.$t('marketing.staraZagora.title'),
          address: this.$t('marketing.staraZagora.address'),
          email: this.$t('marketing.staraZagora.email'),
          phone: this.$t('marketing.staraZagora.phone'),
          phone2: this.$t('marketing.staraZagora.phone2'),
          class: 'staraZagora',
          location: 'ZhTGzREBiqyRSH1c6',
          isSelected: false,
        },
        {
          name: this.$t('marketing.haskovo.title'),
          address: this.$t('marketing.haskovo.address'),
          email: this.$t('marketing.haskovo.email'),
          phone: this.$t('marketing.haskovo.phone'),
          class: 'haskovo',
          location: 'U35kDAimKe2dS1vP9',
          isSelected: false,
        },
      ],
      citiesTr: [
        {
          name: this.$t('marketing.izmir.title'),
          address: this.$t('marketing.izmir.address'),
          email: this.$t('marketing.izmir.email'),
          phone: this.$t('marketing.izmir.phone'),
          class: 'izmir',
          location: '9RFiV9r7vjZJ4zVTA',
          isSelected: false,
        },
      ],
    };
  },
  methods: {
    selectTR(name) {
      for (let i = 0; i < this.citiesTr.length; i++) {
        if (this.citiesTr[i].name !== name) {
          this.citiesTr[i].isSelected = false;
        }
      }

      this.toggleSelectionTR(name);
    },
    toggleSelectionTR(name) {
      const stepsItem = this.citiesTr.find((item) => item.name === name);
      if (stepsItem) {
        stepsItem.isSelected = !stepsItem.isSelected;
      }
    },
    select(name) {
      for (let i = 0; i < this.cities.length; i++) {
        if (this.cities[i].name !== name) {
          this.cities[i].isSelected = false;
        }
      }

      this.toggleSelection(name);
    },
    toggleSelection(name) {
      const stepsItem = this.cities.find((item) => item.name === name);
      if (stepsItem) {
        stepsItem.isSelected = !stepsItem.isSelected;
      }
    },
  },
  mounted() {
    this.cities[0].isSelected = true;
    this.citiesTr[0].isSelected = true;
  },
};
</script>


<style lang="scss" scoped>
@import '@/scss/base.scss';
.representations {
  display: flex;
  justify-content: space-evenly;
  max-width: 1140px;
  margin: auto;

  section {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    padding: 10px;
  }
  .representations__active {
    background: #9561611a;
    color: #f26100;
    position: relative;
    text-decoration: underline;
    :after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -20px;
      border-top: solid 20px #9561611a;
      border-left: solid 20px transparent;
      border-right: solid 20px transparent;
    }
  }
}
.map {
  background: #edf1f3 0% 0% no-repeat padding-box;
  background-size: cover;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 25px 0;
  img {
    max-width: 1140px;
    width: 100%;
  }
  .city {
    background: #f26100;
    box-shadow: 0px 0px 50px #00000099;
    border-radius: 10px;
    cursor: default;
    color: white;
    height: 0%;
    left: 0;
    margin: auto;
    position: absolute;
    padding: 10px;
    right: 0;
    width: 0%;
    z-index: 3;
    @include transition(0.3s);

    &:hover {
      background: #513131;
      height: fit-content;
      width: 300px;
      z-index: 6;

      header,
      section,
      .dash {
        display: flex;
        visibility: visible;
        opacity: 1;
        @include transition(1s);
      }
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }

    .dash {
      height: 1px;
      visibility: hidden;
      width: 100%;
      background: white;
      margin-top: 10px;
    }
    header {
      justify-content: space-between;
      visibility: hidden;
      opacity: 0;

      .exit {
        width: 8%;
      }
    }
    section {
      display: none;
      flex-direction: column;
      gap: 5px;
      opacity: 0;
      padding: 10px;
      text-align: center;
      visibility: hidden;
      h1 {
        align-self: center;
        font: normal normal bold 24px/32px IBM Plex Sans;
      }
      div {
        align-items: center;
        justify-content: center;
        display: flex;
        img {
          width: 5%;
          margin-right: 5px;
        }
      }
    }
  }
  .haskovo {
    bottom: 26%;
    right: -10px;
  }
  .plovdiv {
    bottom: 32%;
    right: 250px;
  }
  .varna {
    top: 37%;
    right: -770px;
  }
  .burgas {
    top: 56%;
    right: -630px;
  }
  .blagoevgrad {
    bottom: 28.5%;
    right: 800px;
  }
  .velikoTurnovo {
    top: 40%;
    left: 30px;
  }
  .montana {
    top: 30%;
    right: 720px;
  }
  .pleven {
    top: 31.5%;
    right: 280px;
  }
  .ruse {
    top: 19%;
    left: 150px;
  }
  .sandanski {
    bottom: 17%;
    right: 750px;
  }
  .staraZagora {
    bottom: 39.5%;
    left: 40px;
  }
  .sofia {
    top: 49%;
    right: 708px;
  }
  .sofia-bz {
    top: 47.5%;
    right: 750px;
  }
  .izmir {
    top: 55%;
    right: 910px;
  }
}
.marketing__container {
  overflow: hidden;

  .texts {
    margin: 0 auto 20px;
    h2 {
      margin: 20px 0;
    }
  }

  .div__box {
    background: transparent linear-gradient(180deg, #e5edf0 0%, #eef2f4 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px;
    max-width: 1800px;
    margin: 0 auto;

    section {
      flex-basis: 23%;
      .div__box__el {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 20px;
        height: 463px;
        position: relative;
        text-align: center;

        .gradient {
          background: transparent linear-gradient(180deg, #00000000 0%, #00000000 65%, #000000 100%) 0% 0% no-repeat
            padding-box;
          border-radius: 20px;
          height: 100%;
        }

        .text {
          text-align: center;
          p {
            color: white;
            font-size: 26px;
            left: 50%;
            margin: 0 auto;
            position: absolute;
            top: 90%;
            transform: translate(-50%, -50%);
            text-align: center;
            line-height: 1;
            width: 90%;
          }
        }
      }
      span {
        color: #444444;
        display: flex;
        padding: 20px;
        text-align: center;
      }
    }
  }

  @media all and(max-width:$xsm) {
    .representations {
      section {
        flex-direction: column;
      }
      :nth-child(2) {
        flex-direction: column-reverse;
      }
      h1 {
        font-size: 14px;
        line-height: 1;
        width: fit-content;
      }
    }
    .map {
      display: flex;
      flex-direction: column;
      background: initial;
      .map__img {
        display: none;
      }
      .city {
        background: #9561611a;
        box-shadow: initial;
        border: 3px solid #513131;
        color: #513131;
        position: initial;
        margin: 10px auto;
        width: 90%;

        header,
        .dash {
          display: none;
          img {
            display: none;
          }
        }
        section {
          display: flex;
          visibility: visible;
          opacity: 1;
          @include transition(1s);
          a,
          img {
            display: none;
          }
        }
        &.selected {
          a,
          img {
            display: block;
          }
          background: #513131;
          color: white;
        }
      }
    }
    h1 {
      width: 50%;
      font-size: 30px;
      line-height: 34px;
      text-align: center;
    }
    .div__box {
      padding: 20px 0;
      section {
        flex-basis: 45%;
        .div__box__el {
          height: 200px;
          .text {
            p {
              font-size: 14px;
            }
          }
        }
      }
      span {
        font-size: 12px;
      }
    }
  }
}
</style>

