var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"whyBalkam__container"},[_c('div',{staticClass:"brands-main"},[_c('div',{staticClass:"texts"},[_c('h2',[_vm._v(_vm._s(_vm.$t('home.whyBalkam.title-brands')))])]),_c('main',{staticClass:"brands-container"},[_c('section',_vm._l((_vm.brands),function(brand){return _c('router-link',{key:brand.route,staticClass:"logo-parent",class:[brand.name],attrs:{"to":brand.route}},[_c('div',{staticClass:"logo",style:({
              backgroundImage: ("url(" + (require(("@/assets/img/" + (brand.img)))) + ")"),
            })})])}),1)])]),_c('section',{staticClass:"whyBalkam-container-section"},[_c('div',{staticClass:"texts"},[_c('h2',[_vm._v(_vm._s(_vm.$t('home.whyBalkam.title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('home.whyBalkam.paragraph')))])]),_c('section',[_c('main',{ref:"whyBalkamBox",staticClass:"whyBalkam-container-main"},[_c('div',{staticClass:"whyBalkam__box__element",class:{ whyBalkam__box__element__active: _vm.active },style:({
            backgroundImage: ("url(" + (require('@/assets/img/home/s1.png')) + ")"),
          })},[_c('div',{staticClass:"gradient"}),_c('div',{staticClass:"text"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('home.whyBalkam.title1'))+" ")]),_c('span',{staticClass:"box-subtitle"},[_vm._v(_vm._s(_vm.$t('home.whyBalkam.desc')))])])]),_c('div',{staticClass:"whyBalkam__box__element",style:({
            backgroundImage: ("url(" + (require('@/assets/img/home/s2.png')) + ")"),
          })},[_c('div',{staticClass:"gradient"}),_c('div',{staticClass:"text"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('home.whyBalkam.title2'))+" ")]),_c('span',{staticClass:"box-subtitle"},[_vm._v(_vm._s(_vm.$t('home.whyBalkam.desc2'))+" ")])])]),_c('div',{staticClass:"whyBalkam__box__element",style:({
            backgroundImage: ("url(" + (require('@/assets/img/home/s3.png')) + ")"),
          })},[_c('div',{staticClass:"gradient"}),_c('div',{staticClass:"text"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('home.whyBalkam.title3'))+" ")]),_c('span',{staticClass:"box-subtitle"},[_vm._v(_vm._s(_vm.$t('home.whyBalkam.desc3'))+" ")])])])]),_c('footer',[_c('router-link',{attrs:{"to":"/contacts"}},[_c('Button',{staticClass:"btn",attrs:{"type":"button"}},[_vm._v(_vm._s(_vm.$t('buttons.button2')))])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }