<template>
  <div>
    <HomeVideo
      title="СЪВМЕСТИМИ ХАРТИЕНИ"
      title2="ПОДОВЕ"
      subtitle="ИЗБЕРЕТЕ НИ ЗА ВАШАТА СОБСТВЕНА МАРКА КАФЕ"
      source="https://www.youtube.com/embed/skv4Hv0GJOI?controls=0&autoplay=1&playlist=skv4Hv0GJOI&loop=1&mute=1"
    />
    <!-- source for ese -->
    <main class="ese__container">
      <div class="parent">
        <div class="text">
          <h2>{{ $t('capsules-and-pods.ese.title') }}</h2>
          <p>{{ $t('capsules-and-pods.ese.desc') }}</p>
        </div>

        <section>
          <div class="img-and-text-left">
            <img src="../../assets/img/capsules-and-pods/Ese/pod1.png" />

            <div>
              <!-- <h2>{{ $t('capsules-and-pods.ese.subtitle') }}</h2> -->
              <p>{{ $t('capsules-and-pods.ese.desc2') }}</p>
            </div>
          </div>
        </section>
        <section>
          <div class="img-and-text-left">
            <img src="../../assets/img/capsules-and-pods/Ese/cup-and-pod.png" />

            <div>
              <h2>{{ $t('capsules-and-pods.ese.first-colomn.title') }}</h2>
              <h1>{{ $t('capsules-and-pods.ese.first-colomn.desc') }}</h1>
              <p>{{ $t('capsules-and-pods.ese.first-colomn.desc2') }}</p>
              <p>{{ $t('capsules-and-pods.ese.first-colomn.desc3') }}</p>
            </div>
          </div>
        </section>

        <section class="packs">
          <h2>{{ $t('capsules-and-pods.ese.third-colomn.title') }}</h2>
          <div class="packs__container" :class="{ packs__active: active }" ref="packagesContainer">
            <div>
              <img src="../../assets/img/capsules-and-pods/Ese/ese.png" />
              <p>{{ $t('capsules-and-pods.ese.third-colomn.desc') }}</p>
            </div>

            <div>
              <img src="../../assets/img/capsules-and-pods/Ese/ese.png" />
              <p>{{ $t('capsules-and-pods.ese.third-colomn.desc2') }}</p>
            </div>

            <div>
              <img src="../../assets/img/capsules-and-pods/Ese/ese.png" />
              <p>{{ $t('capsules-and-pods.ese.third-colomn.desc3') }}</p>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import HomeVideo from './HomeVideo';
export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    isVisible() {
      const wrapper = this.$refs.packagesContainer.getBoundingClientRect();
      // checking if the whole element is between top and bottom of the window

      if (wrapper.top > 0 && wrapper.top < window.innerHeight - wrapper.height) {
        document.removeEventListener('scroll', this.isVisible);
        setTimeout(() => {
          this.active = true;
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.isVisible);
  },
  destroyed() {
    document.removeEventListener('scroll', this.isVisible);
  },
  components: {
    HomeVideo,
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/capsules.scss';

.ese__container {
  color: #444444;
  max-width: $defaultWidth;
  margin: 0 auto;
  text-align: center;
  .parent {
    margin: 0 60px;
  }
  .text {
    margin: 5vh 0;

    h1 {
      margin: 2vh 0;
    }
    p {
      margin: 3vh 0;
    }
  }
  .capsules-container {
    display: flex;
    justify-content: space-around;
    gap: 30px;

    .container-item {
      background: url('../../assets/img/capsules-and-pods/Ese/pod2.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 330px;
      flex-basis: 50%;
      border-radius: 15px;
      position: relative;

      .gradient {
        background: transparent linear-gradient(180deg, #00000000 0%, #00000000 65%, #000000 100%);
        border-radius: 15px;
        position: relative;
        height: 330px;
      }
      .text {
        color: white;
        font-size: 20px;
        font-weight: 600;
        left: 50%;
        bottom: 0%;
        position: absolute;
        transform: translate(-50%, 20%);
        width: 90%;
      }
    }
  }
}
@media all and(max-width: $xsm) {
  .ese__container {
    .parent {
      margin: 0 20px;
    }
  }
  .capsules-container {
    flex-direction: column;
  }
}
</style>