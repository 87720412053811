<template>
  <div class="brands">
    <header class="main-header" :style="{ backgroundImage: `url(${require('@/assets/img/brands/header.png')})` }">
      <h1>{{ $t('brands.title') }}</h1>
    </header>
    <TheProducts />
  </div>
</template>

<script>
import TheProducts from './TheProducts';

export default {
  components: {
    TheProducts,
  },
};
</script>

<style>
</style>