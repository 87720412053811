<template>
  <div class="ftr__numbers">
    <div class="texts">
      <h1 class="center">{{ $t('footer.numbers.title') }}</h1>
      <p class="center">{{ $t('footer.numbers.text1') }}</p>
      <!-- <p class="center">{{ $t('footer.numbers.text2') }}</p>
      <p class="center">{{ $t('footer.numbers.text3') }}</p> -->
    </div>

    <div class="box">
      <div class="ftr__numbers__production">
        <div class="ftr__numbers__row">
          <div class="ftr__numbers__production__item">
            <p><AnimatedNumber :n="1994" :timeout="1000" /><small>г.</small></p>
            <p><b>{{ $t('numbers.n1') }}</b></p>
          </div>

          <div class="ftr__numbers__production__item">
            <p><AnimatedNumber :n="2003" :timeout="1000" /><small>г.</small></p>
            <p>{{ $t('numbers.n2') }}</p>
            <p><b>{{ $t('numbers.n3') }}</b></p>
          </div>

          <div class="ftr__numbers__production__item">
            <p><AnimatedNumber :n="2016" :timeout="1000" /><small>г.</small></p>
            <p>{{ $t('numbers.n4') }}</p>
            <p><b>{{ $t('numbers.n5') }}</b></p>
          </div>

          <div class="ftr__numbers__production__item">
            <p><AnimatedNumber :n="40" /></p>
            <p>{{ $t('numbers.n6') }}</p>
            <p><b> {{ $t('numbers.n7') }}</b></p>
          </div>
        </div>

        <div class="ftr__numbers__row">
          <div class="ftr__numbers__production__item">
            <p><AnimatedNumber :n="100" /></p>
            <p>{{ $t('numbers.n8') }} </p>
            <p> <b> {{ $t('numbers.n9') }}</b></p>
          </div>

          <div class="ftr__numbers__production__item">
            <p><AnimatedNumber :n="12" :timeout="1000" /><small>хил.</small></p>
            <p><b>{{ $t('numbers.n10') }} </b>{{ $t('numbers.n11') }}</p>
            <p>{{ $t('numbers.n12') }} <b>{{ $t('numbers.n13') }}</b></p>
          </div>

          <div class="ftr__numbers__production__item">
            <p><AnimatedNumber :n="135" /><small>млн.</small></p>
            <p>{{ $t('numbers.n14') }} <b>{{ $t('numbers.n15') }}</b></p>
            <p></p>
          </div>

          <div class="ftr__numbers__production__item">
            <p><AnimatedNumber :n="18" /><small>%</small></p>
            <p><b>{{ $t('numbers.n16') }}</b> {{ $t('numbers.n17') }}</p>
            <p><b>{{ $t('numbers.n18') }}</b></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from './AnimatedNumber';
export default {
  components: {
    AnimatedNumber,
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';

.ftr__numbers {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .texts {
    margin: 30px auto;
  }

  .box {
    position: relative;
    background-color: #000;
    background-image: url('../assets/img/footer/balkam-numbers.gif');
    // https://cdn.discordapp.com/attachments/605276279620763648/902073794108223548/test1-animation.gif
    background-size: cover;
    background-position: center;
    display: flex;
  }
  .ftr__numbers__production {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 400px;
    justify-content: center;
    padding: 20px;
    margin: 0 auto;

    &::before {
      background-color: #000;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .ftr__numbers__row {
      display: flex;
      justify-content: center;

      .ftr__numbers__production__item {
        color: #fff;
        text-align: center;
        flex-basis: 45%;
        position: inherit;
        z-index: 1;

        b {
          font-size: 18px;
          font-weight: 600;
        }
        p {
          font-size: 13px;
          font-weight: 300;
          text-align: center;
          &:first-child {
            font-size: 40px;
            font-weight: 200;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $xsm) {
  .ftr__numbers {
    display: flex;
    flex-direction: column;

    .ftr__numbers__production {
      display: flex;
      flex-wrap: wrap;
      background-color: #000;
      background-image: url('https://cdn.discordapp.com/attachments/605276279620763648/902073794108223548/test1-animation.gif');
      background-size: cover;
      background-position: center;
      gap: initial;
      height: fit-content;
      width: 100%;

      &::before {
        background-color: #000;
        content: '';
        height: 100%;
        left: 0;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .ftr__numbers__row {
        flex-wrap: wrap;
        .ftr__numbers__production__item {
          color: #fff;
          text-align: center;
          padding: 20px 0;
          width: 50%;

          b {
            font-size: 13px;
            font-weight: 600;
          }
          p {
            font-size: 13px;
            font-weight: 300;
            &:first-child {
              font-size: 40px;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
</style>