import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Index.vue'
import BalkamGroup from '../views/balkam-group/Index.vue'
import Brands from '../views/brands/Index.vue'
import BrandSpetema from '../views/brands/BrandSpetema.vue'
import BrandBalkam from '../views/brands/BrandBalkam.vue'
import BrandSantos from '../views/brands/BrandSantos.vue'
import BrandLavista from '../views/brands/BrandLavista.vue'
import BrandNapoli from '../views/brands/BrandNapoli.vue'
import BrandVerdico from '../views/brands/BrandVerdico.vue'
import BrandCaprimo from '../views/brands/BrandCaprimo.vue'
import BrandVanHouten from '../views/brands/BrandVanHouten.vue'
import BrandMaison from '../views/brands/BrandMaison.vue'
import BrandTe from '../views/brands/BrandTe.vue'
import BrandBasilur from '../views/brands/BrandBasilur.vue'
import BrandGreenIsland from '../views/brands/BrandGreenIsland.vue'
import Blends from '../views/blends/Index.vue'
import Contacts from '../views/contacts/Index.vue'
import Distribution from '../views/marketing/Index.vue'
// import CapsulesAndPods from '../views/capsules-and-pods/Index.vue'
import DolceGusto from '../views/capsules-and-pods/DolceGusto.vue'
import Lavazza from '../views/capsules-and-pods/Lavazza.vue'
import Nespresso from '../views/capsules-and-pods/Nespresso.vue'
import ESE from '../views/capsules-and-pods/ESE.vue'
import SolubleDrinks from '../views/capsules-and-pods/SolubleDrinks.vue'
import BeansAndGrinded from '../views/capsules-and-pods/BeansAndGrinded.vue'
import CoffeeBeans from '../views/capsules-and-pods/CoffeeBeans.vue'
import Partnership from '../views/capsules-and-pods/Partnership.vue'
import Grinded from '../views/capsules-and-pods/Grinded.vue'
import PageNotFound from '../views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
},
{
  path: '/balkam-group',
  name: 'BalkamGroup',
  component: BalkamGroup,
},
{
  path: '/nespresso',
  name: 'Nespresso',
  component: Nespresso
},
{
  path: '/dolce-gusto',
  name: 'DolceGusto',
  component: DolceGusto
},
{
  path: '/lavazza',
  name: 'Lavazza',
  component: Lavazza
},
{
  path: '/ese',
  name: 'ESE',
  component: ESE
},
{
  path: '/soluble-drinks',
  name: 'SolubleDrinks',
  component: SolubleDrinks
},
{
  path: '/beans-and-grinded',
  name: 'BeansAndGrinded',
  component: BeansAndGrinded
},
{
  path: '/coffee-beans',
  name: 'CoffeeBeans',
  component: CoffeeBeans
},
{
  path: '/grinded',
  name: 'Grinded',
  component: Grinded
},
{
  path: '/brands',
  name: 'Brands',
  component: Brands
},
{
  path: '/brandSpetema',
  name: 'BrandSpetema',
  component: BrandSpetema
},
{
  path: '/brandBalkam',
  name: 'BrandBalkam',
  component: BrandBalkam
},
{
  path: '/brandSantos',
  name: 'BrandSantos',
  component: BrandSantos
},
{
  path: '/brandLavista',
  name: 'BrandLavista',
  component: BrandLavista
},
{
  path: '/brandNapoli',
  name: 'BrandNapoli',
  component: BrandNapoli
},
{
  path: '/BrandVerdico',
  name: 'BrandVerdico',
  component: BrandVerdico
},
{
  path: '/BrandCaprimo',
  name: 'BrandCaprimo',
  component: BrandCaprimo
},
{
  path: '/BrandVanHouten',
  name: 'BrandVanHouten',
  component: BrandVanHouten
},
{
  path: '/BrandMaison',
  name: 'BrandMaison',
  component: BrandMaison
},
{
  path: '/BrandTe',
  name: 'BrandTe',
  component: BrandTe
},
{
  path: '/BrandBasilur',
  name: 'BrandBasilur',
  component: BrandBasilur
},
{
  path: '/BrandGreenIsland',
  name: 'BrandGreenIsland',
  component: BrandGreenIsland
},
{
  path: '/products',
  name: 'Products',
  component: Partnership
},
{
  path: '/blends',
  name: 'Blends',
  component: Blends
},
{
  path: '/distribution',
  name: 'Distribution',
  component: Distribution
},
{
  path: '/contacts',
  name: 'Contacts',
  component: Contacts
},
{
  path: '/:pathMatch(.*)*',
  name: 'PageNotFound',
  component: PageNotFound
},
{
  path: '/about',
  name: 'About',

  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import( /* webpackChunkName: "about" */ '../views/home/AboutBalkam.vue')
}
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) => {
  if(to.name !== 'Home'){
    document.title=`Балкам - ${to.name}`
  }else{
    document.title=`Балкам`
  }
  next()
})

export default router