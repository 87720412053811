<template>
  <div class="blends">
    <header class="main-header" :style="{ backgroundImage: `url(${require('@/assets/img/blends/header.png')})` }">
      <p>{{ $t('blends.subtitle') }}</p>
      <h1>{{ $t('blends.title') }}</h1>
    </header>
    <Blends/>
  </div>
</template>

<script>
import Blends from './Blends';
export default {
    components:{
    Blends,  
  }
};
</script>

<style>
</style>