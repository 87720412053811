var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"hdr"},[_c('div',{staticClass:"hdr-content"},[_c('router-link',{staticClass:"hdr__lg",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/img/logo-h.svg"),"alt":"Balkam"}})]),_c('nav',{staticClass:"hdr__nv",class:{ hdr__active: _vm.open }},[(_vm.open)?_c('TheHeaderLocaleMobile',{staticClass:"mobile__lng"}):_vm._e(),_c('router-link',{staticClass:"hdr__nv__lnk mobile",attrs:{"to":"/"}},[_c('span',[_vm._v(_vm._s(_vm.$t('header.nav.link1.home')))])]),_c('router-link',{staticClass:"hdr__nv__lnk",attrs:{"to":"/balkam-group"}},[_c('span',[_vm._v(_vm._s(_vm.$t('header.nav.link1.name')))]),(!_vm.open)?_c('TheHeaderDropdown',[_c('router-link',{attrs:{"to":{ path: 'balkam-group', query: { scr: 'about' } }}},[_vm._v(" "+_vm._s(_vm.$t('header.nav.link1.link1')))]),_c('router-link',{attrs:{"to":{ path: 'balkam-group', query: { scr: 'cycle' } }}},[_vm._v(_vm._s(_vm.$t('header.nav.link1.link2')))]),_c('router-link',{attrs:{"to":{ path: 'balkam-group', query: { scr: 'technology' } }}},[_vm._v(_vm._s(_vm.$t('header.nav.link1.link3')))]),_c('router-link',{attrs:{"to":{ path: 'balkam-group', query: { scr: 'sustainability' } }}},[_vm._v(_vm._s(_vm.$t('header.nav.link1.link4')))]),_c('router-link',{attrs:{"to":{ path: 'balkam-group', query: { scr: 'certificates' } }}},[_vm._v(_vm._s(_vm.$t('header.nav.link1.link5')))])],1):_vm._e()],1),_c('router-link',{staticClass:"hdr__nv__lnk",class:{
          'router-link-exact-active':
            ['BrandSpetema', 'BrandBalkam', 'BrandSantos', 'BrandNapoli', 'BrandLavista', 'BrandVerdico'].indexOf(
              _vm.$route.name
            ) > -1,
        },attrs:{"to":"/brands"}},[_c('span',[_vm._v(_vm._s(_vm.$t('header.nav.link2.name')))]),(!_vm.open)?_c('TheHeaderDropdown',[_c('router-link',{attrs:{"to":"/brandSpetema"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link1')))]),_c('router-link',{attrs:{"to":"/brandBalkam"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link2')))]),_c('router-link',{attrs:{"to":"/brandSantos"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link3')))]),_c('router-link',{attrs:{"to":"/brandLavista"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link4')))]),_c('router-link',{attrs:{"to":"/brandNapoli"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link5')))]),_c('router-link',{attrs:{"to":"/BrandVerdico"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link6')))]),_c('router-link',{attrs:{"to":"/BrandCaprimo"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link7')))]),_c('router-link',{attrs:{"to":"/BrandVanHouten"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link8')))]),_c('router-link',{attrs:{"to":"/BrandMaison"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link9')))]),_c('router-link',{attrs:{"to":"/BrandTe"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link10')))]),_c('router-link',{attrs:{"to":"/BrandBasilur"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link11')))]),_c('router-link',{attrs:{"to":"/BrandGreenIsland"}},[_vm._v(_vm._s(_vm.$t('header.nav.link2.link12')))])],1):_vm._e()],1),_c('router-link',{staticClass:"hdr__nv__lnk",class:{
          'router-link-exact-active': ['BeansAndGrinded', 'SolubleDrinks'].indexOf(_vm.$route.name) > -1,
        },attrs:{"to":"/products"}},[_c('span',[_vm._v(_vm._s(_vm.$t('header.nav.link3.name')))]),(!_vm.open)?_c('TheHeaderDropdown',[_c('router-link',{attrs:{"to":"/products?c=capsules-and-pods"}},[_vm._v(_vm._s(_vm.$t('header.nav.link3.link1')))]),_c('router-link',{attrs:{"to":"/beans-and-grinded"}},[_vm._v(_vm._s(_vm.$t('header.nav.link3.link2')))]),_c('router-link',{attrs:{"to":"/soluble-drinks"}},[_vm._v(_vm._s(_vm.$t('header.nav.link3.link3')))]),_c('router-link',{attrs:{"to":{ path: 'products', query: { scr: 'vending' } }}},[_vm._v(" "+_vm._s(_vm.$t('header.nav.link3.link4')))])],1):_vm._e()],1),_c('router-link',{staticClass:"hdr__nv__lnk",attrs:{"to":"/blends"}},[_c('span',[_vm._v(_vm._s(_vm.$t('header.nav.link4')))])]),_c('router-link',{staticClass:"hdr__nv__lnk",attrs:{"to":"/distribution"}},[_c('span',[_vm._v(_vm._s(_vm.$t('header.nav.link5')))])]),_c('router-link',{staticClass:"hdr__nv__lnk",attrs:{"to":"/contacts"}},[_c('span',[_vm._v(_vm._s(_vm.$t('header.nav.link6')))])]),(!_vm.open)?_c('TheHeaderLocale'):_vm._e()],1),_c('div',{staticClass:"menu",on:{"click":function($event){_vm.open = !_vm.open}}},[(_vm.open)?_c('img',{attrs:{"src":require("@/assets/img/menu-close.svg"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/img/menu-mobile.svg"),"alt":""}})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }